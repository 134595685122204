import {
    SET_THREAD,
    CLIENT_LOADED,
    SET_CIDLA,
    SET_CLUID,
    SET_MESSAGE_COUNT,
    SET_ADD_MESSAGE,
    ATTACHMENT_ADDED,
    DOCUMENT_UPLOADING,
    DOCUMENT_UPLOADING_ERR,
    SET_FUTURE_THREAD_SUBJECT,
    ADD_MESSAGE,
    MESSAGE_UPLOADED,
    MESSAGE_UPLOAD_FAIL,
    ATTACHMENT_UPLOADED,
    ATTACHMENT_UPLOAD_FAIL,
    ATTACHMENT_UPLOADING,
    MESSAGE_UPLOADING,
    SET_THREAD_HISTORY,
    CLIENT_LOADING,
    CLIENT_LOADING_FAILED,
    THREAD_LOADING,
    THREAD_LOADING_FAILED,
    SET_NEW_NOTE,
    THREAD_HISTORY_LOADING,
    THREAD_HISTORY_LOADING_FAILED,
    INITIAL_LOADING,
    INITIAL_LOADING_DONE,
    INITIAL_LOADING_FAILED, NEW_NOTE_LOADING, NEW_NOTE_LOADING_FAILED, CLIENT_DETAIL_OPEN
} from '../core/const';
import {createAction} from "./humanTask";

const initialState = {
    thread: undefined,
    history: [],
    notes : [],
    client: {
        personalId: "",
        primaryPhoneNumber: "",
        primaryEmail: "",
        address: "",
        fullName: "",
        cluid: ""
    },
    overview: undefined,
    identity: undefined, //logged user
    clientLoading: false,
    threadLoading: false,
    historyLoading: false,
    initialLoading: true,
    historyNoteCreating: createAction(),
    cidla: "",
    cluid: "",
    messageCount: 0,
    documentUploading: false,
    documentUploadingError: false,
    futureThreadSubject: "",
    mergedSubject: "",
    clientDetailOpen: false
};

const messengerReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIAL_LOADING:
            return {...state, initialLoading: true};
        case INITIAL_LOADING_FAILED:
            return {...state, initialLoading: false};
        case INITIAL_LOADING_DONE:
            return {...state, initialLoading: false};
        case CLIENT_DETAIL_OPEN:
            return {...state, clientDetailOpen: action.open};
        case CLIENT_LOADING:
            return {...state, clientLoading: true};
        case CLIENT_LOADED:
            let data = action.clientData;
            data.timestamp = new Date();
            return {...state, clientLoading: false, client: data};
        case CLIENT_LOADING_FAILED:
            return {...state, clientLoading: false};
        case THREAD_LOADING:
            if(action.silent){
                return state;
            }
            return {...state, threadLoading: true};
        case THREAD_LOADING_FAILED: {
            return {...state, threadLoading: false};
        }
        case SET_THREAD:
            //1] init array
            let stateMessages = state?.thread?.messages || [];
            let newMessages = [];
            //2] push new messages
            action.thread.messages.filter(m => !stateMessages.some(tm => tm.id === m.id)).forEach(m => newMessages.push(m));
            //3]
            action.thread.messages = [...stateMessages, ...newMessages];
            return {...state,
                threadLoading: false,
                thread: action.thread,
                mergedSubject: action.thread.subject,
                messageCount: action.thread.messages.length};
        case SET_FUTURE_THREAD_SUBJECT:
            return {...state, futureThreadSubject: action.subject, mergedSubject: action.subject};
        case SET_CIDLA:
            return {...state, cidla: action.cidla};
        case SET_CLUID:
            return {...state, cluid: action.cluid};
        case SET_MESSAGE_COUNT:
            return {...state, messageCount: action.count};
        case SET_ADD_MESSAGE: {
            let messages = [...state.thread.messages];
            //ošetření pollingu při načítání dokumentů
            if(messages.some(m => m.id === action.message.id)){
                messages.find(m => m.id === action.message.id).map(m => {
                    return {...m, attachments: action.message.attachments}
                })
            } else {
                messages.push(action.message);
            }
            return {...state, thread: {...state.thread, messages: messages}}
        }
        case ADD_MESSAGE: {
            let messages = [...state.thread.messages, action.message];
            return {...state, thread: {...state.thread, messages: messages}}
        }
        case MESSAGE_UPLOADING: {
            return {...state,
                thread: {...state.thread,
                    messages: state.thread.messages.map(m => m.uuid === action.uuid ?
                        {...m, flag: {...m.flag, sending: true, error: false}} : m)
                }}
        }
        case MESSAGE_UPLOADED: {
            return {...state,
                thread: {...state.thread,
                    messages: state.thread.messages.map(m => m.uuid === action.uuid ?
                        {...m, id: action.message.id, flag: {...m.flag, sending: false, error: false, unsent: false}} : m)
            }}
        }
        case MESSAGE_UPLOAD_FAIL: {
            return {...state,
                thread: {...state.thread,
                    messages: state.thread.messages.map(m => m.uuid === action.uuid ?
                        {...m, flag: {...m.flag, sending: false, error: true}} : m)
                }}
        }
        case DOCUMENT_UPLOADING: {
            return {...state, documentUploading: action.uploading}
        }
        case DOCUMENT_UPLOADING_ERR: {
            return {...state, documentUploadingError: action.error}
        }
        case ATTACHMENT_UPLOADING: {
            return {...state,
                thread: {...state.thread,
                    messages: state.thread.messages.map(m => m.uuid === action.id ?
                        {...m, attachments: m.attachments.map(a => action.uuid === a.uuid ?
                                {...a, flag: {...a.flag, sending: true, error: false}} : a)} : m
                    )
                }
            }
        }
        case ATTACHMENT_UPLOADED: {
            return {...state,
                thread: {...state.thread,
                    messages: state.thread.messages.map(m => m.uuid === action.id ?
                        {...m, attachments: m.attachments.map(a => a.uuid === action.uuid ?
                                {...a,
                                    size: action.uploaded.size,
                                    duid: action.uploaded.duid,
                                    componentId: action.uploaded.componentId,
                                    filename: action.uploaded.filename,
                                    contentType: action.uploaded.contentType,
                                    flag: {...a.flag, sending: false, error: false, unsent: false}
                                } : a)} : m
                    )
                }
            }
        }
        case ATTACHMENT_UPLOAD_FAIL: {
            return {...state,
                thread: {...state.thread,
                    messages: state.thread.messages.map(m => m.uuid === action.id ?
                        {...m, attachments: m.attachments.map(a => action.uuid === a.uuid ?
                                {...a, flag: {...a.flag, sending: false, error: true, unsent: true}} : a)} : m
                    )
                }
            }
        }
        case ATTACHMENT_ADDED: {
            const messages = [...state.thread.messages];
            return {
                ...state, thread: {
                    ...state.thread, messages: messages.map(m => {
                        if (m.id === action.msg.id) {
                            return {...m, attachments: action.msg.attachments}
                        } else {
                            return m
                        }
                    })
                }
            }
        }
        case THREAD_HISTORY_LOADING: {
            return {...state, historyLoading: true}
        }
        case SET_THREAD_HISTORY: {
            return {...state, history: action.history, historyLoading: false}
        }
        case THREAD_HISTORY_LOADING_FAILED: {
            return {...state, historyLoading: false}
        }
        case SET_NEW_NOTE: {
            return {...state, history: [...state.history, action.note], historyNoteCreating: {...state.historyNoteCreating, progress: false, error: false}}
        }
        case NEW_NOTE_LOADING: {
            return {...state, historyNoteCreating: {...state.historyNoteCreating, progress: true, error: false}}
        }
        case NEW_NOTE_LOADING_FAILED: {
            return {...state, historyNoteCreating: {...state.historyNoteCreating, progress: false, error: true}}
        }
        default:
            return state;
    }
};

export default messengerReducer;
