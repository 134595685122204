import React from "react";
import {useSelector} from "react-redux";
import MessageFloor from "./message/messageFloor";
import MessageGroup from "./message/messageGroup";
import {createMessageGroups} from "./message/messageGrouping";
import {sortBy} from "../../core/utils";
import {ITEM_TYPE} from "../../core/enums";
import {
    APP_SECTION_CONTENT
} from "../../core/const";
import {Col, Icon} from "@george-labs.com/design-system";
import Row from "reactstrap/es/Row";

const ThreadCanvas = () => {
    const messages = useSelector(state => state.messenger.thread?.messages || []);
    const actions = useSelector(state => state.messenger.history || []);
    const layoutContentHeight = useSelector(state => state.layout[APP_SECTION_CONTENT]);
    messages?.map(message => message.type = ITEM_TYPE.MESSAGE);
    actions?.map(action => action.type = ITEM_TYPE.ACTION);
    const sortedItems = sortBy([...messages, ...actions], "createdAt");
    const messageGroups = createMessageGroups(sortedItems);

    const getLastMessageId = () => {
        if (sortedItems.length > 1) {
            let last = sortedItems[sortedItems.length - 1];
            return last.id || last.uuid;
        }
        return "";
    };

    const hasMessages = () => {
        return messages && messages.length > 0;
    };

    return (
        <>
            {hasMessages() ?
                <>
                    {messageGroups.map(group => (
                        <MessageGroup group={group} key={group.groupDate}/>))}

                    <MessageFloor last={getLastMessageId()} height={layoutContentHeight}/>
                </>
                :
                <Row className='h-100 d-flex'>
                    <Col className="my-auto text-center">
                        <Icon icon={Icon.ICON.EMPTY_STATE_GENERIC} size={Icon.SIZE.SIZE_128}
                              color={Icon.COLOR.GREY_500}/>
                        <h3>Zatím žádné zprávy</h3>
                    </Col>
                </Row>
            }
        </>
    )
};


export default ThreadCanvas;