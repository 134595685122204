import React from 'react';
import {useSelector} from 'react-redux';
import ActionStripe from '../../comp/stripe/actionStripe';
import AppSectionContainer from "./container/appSectionContainer";
import {APP_SECTION_HEADER, APP_SECTION_SUBHEADER} from "../../core/const";
import {FocusPage, StripeHeader, StripeTitle} from "@george-labs.com/design-system";

const ApplicationHeader = () => {
    const subject = useSelector(state => state.messenger?.mergedSubject || "");

    const getStripeHeader = () => {
        return (
            <StripeHeader center={<StripeTitle>Zprávy s bankéřem</StripeTitle>}/>
        )
    };

    return <>
        <AppSectionContainer className='g-bg-blue-300' name={APP_SECTION_HEADER} full={true}>
            <FocusPage stripeHeader={getStripeHeader()}/>
        </AppSectionContainer>

        {subject ?
            <AppSectionContainer className='g-bg-white border-bottom' name={APP_SECTION_SUBHEADER}>
                <ActionStripe/>
            </AppSectionContainer>
        : null}
    </>;
};

export default ApplicationHeader;