import {
    ALERT_DANGER,
    USER_LOADED,
    USER_LOADING,
    USER_LOADING_FAILED,
    USER_LOGOUT,
    USER_ROLES_EVALUATED
} from "../core/const";
import Resource from "../core/serverResource"
import {generateUUID} from "../core/utils";
import {TASK_OWNER, TASK_OWNER_THREAD, TASK_VISITOR, TASK_LAST_OWNER, TASK_OWNER_FRONT_OFFICE_SOLVER_MMA, NOT_OWNER_FRONT_OFFICE_SOLVER_EPB} from "../comp/roles/availableRoles";
import {setUsersPhotos} from "./messengerActions";
import {fireShowAlert} from "./alertActions";

export const userLoading = () => (
    {type: USER_LOADING}
);

export const userLoaded = (identity) => (
    {type: USER_LOADED, identity, session: generateUUID()}
);

const userRolesEvaluated = (userRoles) => (
    {type: USER_ROLES_EVALUATED, userRoles}
);

export const userLoadingFailed = (err) => (
    {type: USER_LOADING_FAILED, err}
);

export const userLogout = () => (
    {type: USER_LOGOUT}
);

const systemUsernames = ["system", "SMART", "SADMIN","1-LEOT1Z3"]

export function fireGetLoggedUser() {
    return (dispatch) => {
        dispatch(userLoading());
        return fetch(Resource.getMitApi() + "/user", {
            method: "GET",
            headers: {"Content-Type": "application/json" }
        }).then(Resource.checkStatus)
          .then(response => response.json())
          .then(user => {
              //TODO tohle pak smazat
              user.roles.map(r => r.toUpperCase());
              dispatch(userLoaded(user));
              return Promise.resolve(user);
          }).catch(err => {
              dispatch(fireShowAlert("Vaše informace se nepodařilo načíst", ALERT_DANGER, 10000));
              dispatch(userLoadingFailed(err));
              return Promise.reject(err);
        });
    };
}


export function getUserPhoto(username) {
    return (dispatch, getState) => {
        let userPhoto = getState().user.photos[username];
        if(userPhoto){
            return Promise.resolve(userPhoto);
        }
        if(!username || userPhoto==="blank"){
            return Promise.resolve();
        }
        if(systemUsernames.includes(username)){
            setUsersPhotos(username, "blank");
            return Promise.resolve();
        }
        return fetch(Resource.getMitApi() + "/user/photo/" + username, {
            method: "GET"
        })
            .then(response => response.json())
            .then((binary) => {
                dispatch(setUsersPhotos(username, binary));
            })
            .catch(err => {
                dispatch(setUsersPhotos(username, "blank")); //zatím takhle
            });
    }
}

export function fireLogout() {
    return (dispatch) => {
        window.sessionStorage.clear();
        window.location.replace(window.location.href);
        dispatch(userLogout())
    }
}

// This method contains logic with evaluating, what roles is the user in.
export function evaluateUserRoles(user, humanTask, messageCount) {

    return (dispatch) => {
        let userRoles = [];
        // 1. Evaluate owner vs. visitor
        if (user.username !== humanTask.actualOwner && humanTask.taskStatus === 'IN_PROGRESS' && (user.roles.includes("SMART_MIT_solver_frontOfficeEP", "SMART_MIT_solver_frontOfficeEPB"))) {
            userRoles.push(NOT_OWNER_FRONT_OFFICE_SOLVER_EPB);
        }
        else if (user.username === humanTask.actualOwner && humanTask.taskStatus === 'IN_PROGRESS' && (user.roles.includes("SMART_MIT_Solver_FrontOffice"))) {
            userRoles.push(TASK_OWNER_FRONT_OFFICE_SOLVER_MMA);
        }
        if (user.username === humanTask.actualOwner && humanTask.taskStatus === 'IN_PROGRESS') {
            if (messageCount > 0) {
                userRoles.push(TASK_OWNER_THREAD);
            }
            userRoles.push(TASK_OWNER);
        } else if (user.username === humanTask.actualOwner && (humanTask.taskStatus === 'COMPLETED' || humanTask.taskStatus === 'EXITED')) {
            userRoles.push(TASK_LAST_OWNER);
        } else {
            userRoles.push(TASK_VISITOR);
        }
        dispatch(userRolesEvaluated(userRoles));
    }
}
