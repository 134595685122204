import React from "react";
import {DeviceProvider} from "./deviceContext";

const DeviceContextHandler = (props) =>  {

    return (
        <DeviceProvider
            value={{
                width: window.innerWidth,
                height: window.innerHeight
            }}
        >
            {props.children}
        </DeviceProvider>
    )
};

export default DeviceContextHandler;