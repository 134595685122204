import { Row, Col } from "reactstrap";
import Message from "./message";
import Action from "./action";
import { useDispatch, useSelector } from "react-redux";
import { downloadDocument } from "../../../actions/messengerActions";
import { ITEM_TYPE } from "../../../core/enums";
import BadgeComp from "./badgeComp";

const MessageGroup = (props) => {
    const documentUploading = useSelector(state => state.messenger.documentUploading || false);
    const dispatch = useDispatch();

    return (
        <>
            <Row className={"message-group"}>
                <Col>
                    {props.group.groupDate ?
                        <Row>
                            <BadgeComp label={props.group.groupDate}/>
                        </Row> : null}
                    {props.group.messages.map((item, i) => {
                        return item.type === ITEM_TYPE.MESSAGE ? <Message message={item}
                                        key={item.id}
                                        handleDownloadDocument={(doc) => dispatch(downloadDocument(doc))}
                                        isLast={i === props.group.messages.length -1}
                                        documentUploading={documentUploading}/>
                            : <Action key={item.uuid} action={item}/>
                    })}
                </Col>
            </Row>
        </>
    );
}

export default MessageGroup;