import {useDispatch, useSelector} from "react-redux";
import {Col, Container, Row} from "reactstrap";
import {menuToggled, notesToggled} from "../../actions/layoutAction";
import {Button, Icon, Paragraph} from "@george-labs.com/design-system";
import React, {useEffect, useState} from "react";
import {
    APP_SECTION_ADD_NOTE_BUTTON,
    APP_SECTION_HEADER,
    APP_SECTION_MESSAGE_CREATOR, APP_SECTION_NOTE_BUTTONS,
    APP_SECTION_NOTE_UPDATE,
    APP_TOTAL_HEIGHT
} from "../../core/const";
import NotesBody from "../thread/history/notesBody";
import AppSectionContainer from "./container/appSectionContainer";
import {isActualOwner, isTaskActive} from "../stripe/utils/actionStripeConfig";
import MessageCreator from "../thread/messageCreator";
import InactiveMessageCreator from "../thread/inactiveMessageCreator";

const ApplicationNotes = () => {
    const menuOpen = useSelector(state => state.layout.menuOpen);
    const notesOpen = useSelector(state => state.layout.notesOpen);
    const applicationHeight = useSelector(state => state.layout[APP_TOTAL_HEIGHT]);
    const applicationHeader = useSelector(state => state.layout[APP_SECTION_HEADER]);

    const [updateMode, setUpdateMode] = useState(false);

    const [style, setStyle] = useState({
        height: '100vh'
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if(applicationHeight && applicationHeader) {
            setStyle({...style, height: (applicationHeight - applicationHeader) + 'px'})
        }
    }, [applicationHeight, applicationHeader]);

    const closeAllMenus = () => {
        setUpdateMode(false);
        dispatch(notesToggled(!notesOpen));
        dispatch(menuToggled(!menuOpen));
    }

    const resolveNoteMenuState = () => {
        //Close whole Notes menu only when we are not in update mode
        if (updateMode) {
            setUpdateMode(false);
        } else {
            dispatch(notesToggled(!notesOpen));
            setUpdateMode(false);
        }
    }

    const updateModeHandler = (mode) => {
        setUpdateMode(mode);
    }

    return (
        <>
            {notesOpen ?
                <Container fluid className='right-menu fixed-top fixed-bottom' style={{top: (applicationHeader) + 'px'}}>
                    <Row style={style}>
                        <Col md={6} lg={9} className="d-none d-sm-block backdrop" onClick={closeAllMenus} >
                        </Col>
                        <Col xs={12} md={6} lg={3} className="bg-white border-left h-100 d-flex flex-column">
                            <AppSectionContainer className='g-bg-white py-2' name={APP_SECTION_NOTE_BUTTONS}>
                                <Row className="mt-2">
                                    <Col className="text-left">
                                        <Paragraph onClick={resolveNoteMenuState} className='selectable'>
                                            <Icon icon={Icon.ICON.ARROW_LEFT} color={Icon.COLOR.BLUE_300}
                                                  size={Icon.SIZE.SIZE_24}/>
                                            <span className='ml-1 text-primary font-weight-bold'>
                                            Zpět
                                        </span>
                                        </Paragraph>
                                    </Col>
                                    <Col className="text-right">
                                        <Paragraph onClick={closeAllMenus} className='selectable'>
                                            <Icon icon={Icon.ICON.ERROR} color={Icon.COLOR.BLUE_300}
                                                  size={Icon.SIZE.SIZE_24}/>
                                            <span className='ml-1 text-primary font-weight-bold'>
                                            Zavřít
                                        </span>
                                        </Paragraph>
                                    </Col>
                                </Row>
                            </AppSectionContainer>
                            <NotesBody updateMode={updateMode} updateModeHandler={updateModeHandler}/>
                            { !updateMode ?
                                <AppSectionContainer className='g-bg-white py-2' name={APP_SECTION_ADD_NOTE_BUTTON}>
                                    <Row className="border-top pt-4 pb-4">
                                        <Col>
                                            <Button icon={Button.ICON.PLUS} fullWidth={Button.FULL_WIDTH.ALWAYS} onClick={() => setUpdateMode(true)}>
                                                Přidat
                                            </Button>
                                        </Col>
                                    </Row>
                                </AppSectionContainer>
                                : null }
                        </Col>
                    </Row>
                </Container> : null}
        </>
    )
}

export default ApplicationNotes;