import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './App.scss';
import {Provider} from "react-redux";
import store from "./core/store";
import {AppWithAuth} from './AppWithAuth';
import '@george-labs.com/design-system/dist/bootstrap.min.css';
import '@george-labs.com/design-system/dist/gds-main.min.css';
import '@george-labs.com/design-system/dist/gds-store.min.css';
import "@george-labs.com/design-system/dist/gds-components.min.css";
import {LanguageProvider, ThemeProvider} from '@george-labs.com/design-system';
import DeviceContextHandler from "./core/context/deviceContextHandler";
import csStrings from './locales/cs-CZ/gds-translations.json';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <LanguageProvider locale={"cs"} strings={csStrings}>
                <ThemeProvider theme={ThemeProvider.THEME.STORE}>
                    <DeviceContextHandler>
                        <AppWithAuth>
                            <App/>
                        </AppWithAuth>
                    </DeviceContextHandler>
                </ThemeProvider>
            </LanguageProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
