import {ButtonGroup, ModalBody, Paragraph, SmallModal, Button} from "@george-labs.com/design-system";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {toggleCancelModal} from "../../actions/layoutAction";
import {fireCancel} from "../../actions/humanTaskActions";

const CancelModal = () => {
    const cancelModalOpen = useSelector(state => state?.layout?.cancelModalOpen || false);
    const cancelProgress = useSelector(state => state?.humanTask?.cancelling?.progress || false);
    const dispatch = useDispatch();

    const getFooter = () => {
        return (
            <ButtonGroup>
                <Button onClick={() => dispatch(toggleCancelModal(false))}
                        variant={Button.VARIANT.SECONDARY}>
                    Zrušit
                </Button>
                <Button onClick={() => dispatch(fireCancel())}
                        variant={Button.VARIANT.PRIMARY}>
                    Potvrdit
                </Button>
            </ButtonGroup>
        )
    };

    return (
        <>
            <SmallModal isOpen={cancelModalOpen}
                        isLoading={cancelProgress}
                        onClose={() => dispatch(toggleCancelModal(false))}
                        smallColor={SmallModal.SMALL_COLOR.RED}
                        footer={getFooter()}
                        title='Storno konverzace'>
                <ModalBody>
                    <Paragraph>
                        Rozpracovaná a neodeslaná konverzace bude smazána
                    </Paragraph>
                </ModalBody>
            </SmallModal>
        </>
    )
};

export default CancelModal