import React, {useEffect, useState} from 'react';
import {fireInitialLoading, setCidla} from "./actions/messengerActions";
import {useDispatch, useSelector} from "react-redux";
import {extractParamFromURL} from "./core/utils";
import DataLoader from './comp/callers/dataLoader';
import {APP_TOTAL_HEIGHT, PARAM_CIDLA} from "./core/const";
import AppLoadingModal from "./comp/modals/appLoadingModal";
import {isUserInFORole, isUserInKCRole} from "./comp/stripe/utils/actionStripeConfig";
import AlertModal from "./comp/modals/alertModal";
import {fireLoadEnvironment} from "./actions/environmentActions";
import ClientModal from "./comp/modals/clientModal";
import ApplicationMenu from "./comp/sections/applicationMenu";
import ApplicationHeader from "./comp/sections/applicationHeader";
import ApplicationContent from "./comp/sections/applicationContent";
import ApplicationMessageCreator from "./comp/sections/applicationMessageCreator";
import ApplicationSnackbar from "./comp/sections/applicationSnackbar";
import {layoutUpdate} from "./actions/layoutAction";
import CancelModal from "./comp/modals/cancelModal";
import ApplicationNotes from "./comp/sections/applicationNotes";

const App = () => {
    const dispatch = useDispatch();
    const cidla = useSelector(state => state?.messenger?.cidla);
    const initialLoading = useSelector(state => state?.messenger?.initialLoading);
    const user = useSelector(state => state?.user?.identity);
    const history = useSelector(state => state.messenger.history || []);

    const delegatedActions = history.filter(a => a.actionType === "DELEGATE_SLA");
    const showAlert = isUserInFORole(user) && !isUserInKCRole(user) && (delegatedActions.length > 0);
    const [alertModalOpen, setAlertModalOpen] = useState(false);

    useEffect(() => {
        let urlCidla = extractParamFromURL(PARAM_CIDLA);
        if (urlCidla) {
            dispatch(setCidla(urlCidla));
            dispatch(fireInitialLoading(urlCidla))
        }
        dispatch(fireLoadEnvironment());
    }, []);

    useEffect(() => {
        if (showAlert === true) {
            setAlertModalOpen(true);
        }
    }, [showAlert]);

    useEffect(() => {
        const updateWindowDimensions = () => {
            dispatch(layoutUpdate(APP_TOTAL_HEIGHT, window.innerHeight));
        };

        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);

    const isContentAvailable = () => {
        return !!cidla;
    };

    const orchestrateView = () => {
        if (!isContentAvailable()) {
            return (
                <>
                    {/*header section*/}
                    <ApplicationHeader/>

                    {/*content section*/}
                    <ApplicationContent/>
                </>
            )
        }

        if (initialLoading) {
            return <AppLoadingModal/>
        } else {
            return (
                <>
                    {/*header section*/}
                    <ApplicationHeader/>

                    {/*content section*/}
                    <ApplicationContent/>

                    {/*message creator section*/}
                    <ApplicationMessageCreator/>

                    {/*application menu*/}
                    <ApplicationMenu/>

                    {/*application notes*/}
                    <ApplicationNotes/>

                    {/*Application notifications*/}
                    <ApplicationSnackbar/>

                    <AlertModal
                        isOpen={alertModalOpen}
                        onToggle={() => setAlertModalOpen(false)}
                        submitText={"Beru na vědomí"}
                        header={"Nejste řešitelem"}
                        alertText={"Konverzace byla z důvodu Vaší nečinnosti předána na náhradního řešitele."}/>

                    <ClientModal/>
                    <CancelModal/>
                    <DataLoader/>
                </>
            )
        }
    };

    return (
        <>
            {orchestrateView()}
        </>
    );
};

export default App;
