import React from "react";
import {Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import ActionsModalFooter from "./actionsModalFooter";
import MessengerModal from "./messengerModal";

export const AlertModal = ({isOpen, onToggle, onSubmit, header, submitText, alertText}) => {

    const handleSubmit = () => {
        onToggle();
    };

    const getBody = () => {
        return (
            <>
                <Row>
                    <Col>{alertText}</Col>
                </Row>
            </>
        )
    };

    const getFooter = () => {
        let primary = {
            onClick: handleSubmit,
            text: submitText
        };

        return (
            <ActionsModalFooter primary={primary}/>
        )
    };

    return (
        <MessengerModal
            size="xs"
            isOpen={isOpen}
            onToggle={onToggle}
            header={header}
            body={getBody()}
            footer={getFooter()}
        />
    )
};

export default AlertModal