import React from "react";
import {Col, Icon, Modal, ModalBody, ModalFooter, Row, Button} from "@george-labs.com/design-system";

const DialogInfo = ({isOpen, icon, onSubmit, onToggle, header, info, submitLabel, toggleLabel = "Zavřít", closeable=true}) => {
    const ICON_DIMENSION = 24;

    const getTitle = () => {
        return (
            <Row>
                <Col className="col-xs-1">
                    <Icon icon={icon} size={ICON_DIMENSION}/>
                </Col>
                <Col className="col-xs-11">
                    <span className="font-weight-bold font-size-12">{header}</span>
                </Col>
            </Row>
        )
    };

    return (
        <Modal isOpen={isOpen} title={getTitle()} closeable={closeable}>
            <ModalBody className="py-0">
                <Row className="mt-3 mb-3">
                    <Col>
                        {info}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                {onToggle ?
                    <Button variant={Button.VARIANT.SECONDARY} outline onClick={onToggle} className={onToggle && onSubmit ? 'mr-2' : ''}>{toggleLabel}</Button> : null}
                {onSubmit ?
                    <Button variant={Button.VARIANT.PRIMARY} onClick={onSubmit}>{submitLabel}</Button> : null}
            </ModalFooter>
        </Modal>
    )
};

export default DialogInfo;