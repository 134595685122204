import {Col, Row} from "reactstrap";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    createThreadForCaseWithMessage, createThreadMessage,
    sendMessage
} from "../../actions/messengerActions";
import {Button, Icon, TextInput} from '@george-labs.com/design-system';
import {SmartDropzone, SmartFile} from "@csas-smart/smart-ui-comps";
import Badge from "reactstrap/es/Badge";
import {createFileKey} from "@csas-smart/smart-ui-comps/dist/controlled/smartFile";
import {findConfiguration} from "../stripe/utils/actionStripeConfig";
import {ACCEPTED_EXTENSIONS, ACCEPTED_MIMETYPES, ALERT_DANGER, MAX_MESSAGE_LENGTH} from "../../core/const";
import {getReadableFileSizeString} from "../../core/utils";
import MessageRichtextInput from "./message/messageRichtextInput";
import { fireShowAlert } from "../../actions/alertActions";
import MobileRichtextInput from "./message/mobileRichtextInput";


const MessageCreator = () => {
    const threadCanvas = document.getElementById("threadCanvasSection");
    const dispatch = useDispatch();

    /**
     * The state that holds the content of the message the client is writing on desktop (needs to be separated from the mobile one).
     */
    const [singleMessage, setSingleMessage] = useState("");

    /**
     * The state that holds the content of the message the client is writing on mobile (needs to be separated from the mobile one).
     */
    const [singleMobileMessage, setSingleMobileMessage] = useState("");

    const [filesEspis, setFilesEspis] = useState([]);
    const cidla = useSelector(state => state.messenger.cidla || "");
    const subject = useSelector(state => state.messenger?.mergedSubject || "");
    const [attachmentAreaOpen, setAttachmentAreaOpen] = useState(false);
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [duid, setDuid] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);


    const thread = useSelector(state => state?.messenger?.thread);
    const client = useSelector(state => state?.messenger?.client);
    const user = useSelector(state => state?.user?.identity);
    const humanTask = useSelector(state => state?.humanTask?.humanTask);
    const messageCount = useSelector(state => state?.messenger?.messageCount);
    const [config, setConfig] = useState();

    useEffect(() => {
        let config = findConfiguration(humanTask, thread, client, user);
        setConfig(config)
    }, [humanTask?.actualOwner, humanTask?.taskStatus, messageCount]);


    const handleSendMessage = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        const messageToBeSent = getMessageContent();
        console.log("Message to be sent: ", messageToBeSent);
        if ((messageToBeSent || acceptedFiles.length > 0 || filesEspis.length > 0) && ((thread && thread.id) || (subject !== null && subject.length !== 0))) {

            // thread does not yet exist
            if (thread && !thread.id) {
                compositeMessageLocal();
            }else {
                sendMessageLocal();
            }
        }
    };

    /**
     * The component has two fields to store the state of the message the client is writing - one state would cause shenenigans in the ReactQuill component.
     * The function determines which mode is currently active (phone/pc) and returns the message state.
     * @returns The content of the message that the client is to submit.
     */
    const getMessageContent = () => {
        return singleMessage && singleMessage.length > 0 ? singleMessage : singleMobileMessage;
    }

    /**
     * Method that clears the message content from the undesired sequences.
     * @returns The message that does not contain decorating sequences.
     */
    const improveMessageContent = (message) => {
        if (isEmptyMessage()){
            return message;
        }
        let clearedMessage = message
            // fix the double breaks
            .replaceAll("<div><br></div>", "\n")
            // fix the newlines
            .replaceAll("</div>", "").replaceAll("<div>", "\n");

        if (clearedMessage.includes('<span class="ql-cursor">')){             
            // Fix the end with the cursor holding predefined style. The cursor is represented as a span
            // with given class, that contains an unknown character: <span class="ql-cursor">﻿</span>
            const searchSequenceStart = '<span class="ql-cursor">';
            const searchSequenceEnd = '</span>';
            const startOfCursor = clearedMessage.indexOf(searchSequenceStart);
            const endOfCursor = clearedMessage.indexOf(searchSequenceEnd);
            clearedMessage = clearedMessage.slice(0, startOfCursor) + clearedMessage.slice(endOfCursor + searchSequenceEnd.length);
        }
        return clearedMessage;
    }

    const compositeMessageLocal = () => {
        // fix for double break
        const messageToBeSent = getMessageContent();
        let singleMessageFixed = improveMessageContent(messageToBeSent);
        dispatch(createThreadMessage(singleMessageFixed, subject, humanTask?.caseType, thread.cluid, acceptedFiles, filesEspis)).then(() => {
            // ThreadCanvas might not exist, if the thread was just created.
            if (threadCanvas) {
                threadCanvas.scrollTop = threadCanvas.scrollHeight;
            }
            setButtonDisabled(false);
        })
        setFilesEspis([]);
        setAcceptedFiles([]);
        setSingleMessage(null);
        setSingleMobileMessage(null);
        setAttachmentAreaOpen(false);
        setButtonDisabled(false);
    };

    const sendMessageLocal = () => {
        // fix for double break
        const messageToBeSent = getMessageContent();
        let singleMessageFixed = improveMessageContent(messageToBeSent);

        dispatch(sendMessage(singleMessageFixed, acceptedFiles, filesEspis)).then(() => {
            // ThreadCanvas might not exist, if the thread was just created.
            if (threadCanvas) {
                threadCanvas.scrollTop = threadCanvas.scrollHeight;
            }
            setButtonDisabled(false);
        })
        setFilesEspis([]);
        setAcceptedFiles([]);
        setSingleMessage(null);
        setSingleMobileMessage(null);
        setAttachmentAreaOpen(false);
        setButtonDisabled(false);
    };

    const onUploadFileEspis = (file) => {
        setFilesEspis([...filesEspis, file]);
        setDuid('')
    };

    /**
     * Function checks, if the message that is created in the rich text area is empty.
     * Function removes empty chars and HTML tags to see if there is any actual content
     * @returns
     */
    const isEmptyMessage = () => {
        let message = getMessageContent();
        if (!message || message.length === 0){
            return true;
        }
        return !message || !message.replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('<br>', '').replaceAll('<br/>', '').replaceAll(' ', '').replaceAll('<ul>', '').replaceAll('</ul>', '').replaceAll('<ol>', '').replaceAll('</ol>', '')
    }

    const isDisabled = () => {
        return (!subject && !thread) || (acceptedFiles.length === 0 && filesEspis.length === 0 && isEmptyMessage())
    };

    const onFilesAcceptedDrop = (acceptedFilesArr) => {
        let filtered = acceptedFilesArr.filter(f => !acceptedFiles.some(acc => createFileKey(f) === createFileKey(acc)));
        setAcceptedFiles([...acceptedFiles, ...filtered]);
    };

    const onFilesRejectedDrop = (rejectedFilesData) => {
        let filtered = rejectedFilesData.filter(d => !rejectedFiles.some(rej => createFileKey(rej) === createFileKey(d.file)));
        setRejectedFiles([...rejectedFiles, ...filtered]);
    };

    const onAcceptedFileRemove = (file) => {
        let remainingFiles = [...acceptedFiles.filter(f => createFileKey(f) !== createFileKey(file))];
        setAcceptedFiles(remainingFiles);
    };

    const onRejectedFileRemove = (file) => {
        let remainingFiles = [...rejectedFiles.filter(d => createFileKey(d.file) !== createFileKey(file))];
        setRejectedFiles(remainingFiles);
    };

    const castToFile = (espis) => {
        return {
            name: espis,
            type: 'ESPIS'
        }
    };

    const handleEspisRemove = (file) => {
        let remainingFiles = [...filesEspis.filter(f => file.name !== f)];
        setFilesEspis(remainingFiles);
    };

    const handleEvent = (newValue) => {
        setSingleMessage(newValue);
    };

    const handleMobileEvent = (newValue) => {
        setSingleMobileMessage(newValue)
    };

    const hasAttachments = () => {
        return acceptedFiles.length > 0 || filesEspis.length > 0;
    };

    const onCustomValidate = (file) => {
        let acceptedExtensions = ACCEPTED_EXTENSIONS.map(ae => ae.replaceAll('.', ''));
        let extension = file?.name?.split('.').pop();

        if(!acceptedExtensions.some(r => r.toUpperCase() === extension?.toUpperCase())){
            return {
                code: "file-invalid-type",
                message: 'this type is not accepted'
            };
        }
        return null;
    };

    const getAcceptedMimeTypes = () => {
        return ACCEPTED_MIMETYPES.join(',');
    };

    const getAcceptedExtensions = () => {
        return ACCEPTED_EXTENSIONS.join(',');
    };

    const MAX_SIZE = 10485760;

    return (
        <>
            {/*reimplementation - might suck, might not, we'll see*/}
            {/*main area - attachment btn, input area, send btn*/}
            <Row className={"mb-4 " + (config?.sendDisabled ? 'disabled' : '')}>
                <Col className="col-auto">
                    <Button className="px-1 py-0" variant={Button.VARIANT.SECONDARY}
                            disabled={config?.sendDisabled}
                            fullWidth="never"
                            onClick={() => !setAttachmentAreaOpen(!attachmentAreaOpen)}>
                        <Icon icon={Icon.ICON.ATTACHMENT} size={24}/>
                    </Button>
                </Col>
                <Col className="px-0">
                    <Row>
                        {attachmentAreaOpen ?
                            <Col md={12} className="position-relative p-0">
                                <div className="w-100 attachment-area p-3">
                                    <Row>
                                        <Col className="text-center">
                                            <p className="p-0 mb-2"><strong>Nahrajte přílohu</strong></p>
                                        </Col>
                                        <Col className="col-md-auto position-absolute pointer" title="Zavřít" style={{right: 0}}
                                             onClick={() => setAttachmentAreaOpen(false)}>
                                            <Icon icon={Icon.ICON.ERROR}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="d-none d-sm-block col-md-auto">
                                            <Badge color="info" className="rounded-icon">
                                                <Icon icon={Icon.ICON.FILE_UPLOAD}/>
                                            </Badge>
                                        </Col>
                                        <Col>
                                            <SmartDropzone id="attachment-zone"
                                                           tooLargeLabel={"Soubor který se pokoušíte nahrát je příliš velký. Maximální velikost je: " + getReadableFileSizeString(MAX_SIZE)}
                                                           invalidTypeLabel={"Soubor je nepovoleného typu, povolené soubory jsou pouze: " + getAcceptedExtensions()}
                                                           multiple={true}
                                                           maxSize={MAX_SIZE}
                                                           rejectedFiles={rejectedFiles}
                                                           acceptedFiles={acceptedFiles}
                                                           onFilesAcceptedDrop={onFilesAcceptedDrop}
                                                           onFilesRejectedDrop={onFilesRejectedDrop}
                                                           onAcceptedFileRemove={onAcceptedFileRemove}
                                                           onRejectedFileRemove={onRejectedFileRemove}
                                                           validator={onCustomValidate}
                                                           accept={getAcceptedMimeTypes()}
                                                           text="Nahrajte soubory ze svého zařízení"/>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row className="mt-4">
                                        <Col className="d-none d-sm-block col-md-auto">
                                            <Badge color="info" className="rounded-icon">
                                                <Icon icon={Icon.ICON.E_MAIL}/>
                                            </Badge>
                                        </Col>
                                        <Col className="col-sm-auto mb-2">
                                            <TextInput placeholder="Zadejte DUID z ESPIS" value={duid} onChange={(e) => setDuid(e.target.value)}/>
                                        </Col>
                                        <Col className="pl-0 col-3 col-md-auto">
                                            <Button onClick={() => onUploadFileEspis(duid)} title="Připojit dokument" disabled={!duid}>
                                                <Icon icon={Icon.ICON.INVOICE_DIGITAL}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col> : null}
                    </Row>
                    <Row className="textarea-expanding w-100 mx-0">
                        <div className="d-none d-sm-block w-100">
                            <MessageRichtextInput onChange={handleEvent} disabled={config?.sendDisabled} value={singleMessage} />
                        </div>
                        <div className="d-block d-sm-none w-100">
                            <MobileRichtextInput onChange={handleMobileEvent} disabled={config?.sendDisabled} value={singleMobileMessage} />
                        </div>
                        
                            {hasAttachments() ?
                            <div className="p-0 mt-2">
                                {acceptedFiles.map(f => <SmartFile key={f.name} file={f} onRemove={onAcceptedFileRemove}/>)}
                                {filesEspis.map(f => <SmartFile key={f} file={castToFile(f)} onRemove={() => handleEspisRemove(castToFile(f))}/>)}
                            </div> :null}
                    </Row>
                </Col>
                <Col className="col-auto">
                    <Button disabled={(config?.sendDisabled || isDisabled() || buttonDisabled)}
                            className="px-1 py-0" variant={Button.VARIANT.PRIMARY}
                            title="Odeslat"
                            fullWidth="never"
                            onClick={handleSendMessage}>
                        <Icon icon={Icon.ICON.ARROW_RIGHT} size={24}/>
                    </Button>
                </Col>
            </Row>
        </>
    );
}


export default MessageCreator;
