import {
    HUMAN_TASK_LOADED,
    UPDATE_HUMAN_TASK,
    ACTUAL_OWNER_FULLNAME,
    HUMAN_TASK_LOADING_FAILED,
    HUMAN_TASK_LOADING,
    HUMAN_TASK_ACTION
} from "../core/const";

const initialState = {
    humanTask: null,
    actualOwnerFullName: null,
    firstOpenedByBankerDate: null,
    loading: false,
    error: false,
    completing: createAction(),
    delegating: createAction(),
    complaining: createAction(),
    claiming: createAction(),
    restoring: createAction(),
    cancelling: createAction(),
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case HUMAN_TASK_LOADING:
            return {...state, loading: true, error: false};
        case HUMAN_TASK_LOADED:
            return {...state, humanTask: action.humanTask, loading: false};
        case HUMAN_TASK_LOADING_FAILED:
            return {...state, humanTask: action.humanTask, loading: false, error: true};
        case HUMAN_TASK_ACTION:
            return {...state, [action.action]: {...state[action.action], progress: action.progress, error: action.error}};
        case UPDATE_HUMAN_TASK:
            if (action.humanTask.id === null || action.humanTask.actualOwner === null || action.humanTask.taskStatus === null){
                return state;
            }
            let updatedHumanTask = {...state.humanTask};
            updatedHumanTask.id = action.humanTask.id;
            updatedHumanTask.actualOwner = action.humanTask.actualOwner;
            updatedHumanTask.taskStatus = action.humanTask.taskStatus;
            return {...state, humanTask: updatedHumanTask};
        case ACTUAL_OWNER_FULLNAME:
            return {...state, actualOwnerFullName: action.actualOwnerFullName};
        default:
            return state;
    }
};

export function createAction(){
    return {
        progress: false,
        error: false
    }
}

export default userReducer;
