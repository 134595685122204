import {useSelector} from "react-redux";
import AppSectionContainer from "./container/appSectionContainer";
import {APP_SECTION_MESSAGE_CREATOR} from "../../core/const";
import React from "react";
import MessageCreator from "../thread/messageCreator";
import {isTaskActive, isActualOwner, isThreadDeleted, isThreadClosed, isHumanTaskExpired} from '../stripe/utils/actionStripeConfig'
import InactiveMessageCreator from "../thread/inactiveMessageCreator";

const ApplicationMessageCreator = () => {
    const subject = useSelector(state => state.messenger?.mergedSubject || "");
    const {humanTask} = useSelector(state => state?.humanTask || {});
    const user = useSelector(state => state?.user?.identity);
    const thread = useSelector(state => state?.messenger?.thread);

    return (
        <>
            {subject?
                <AppSectionContainer className='g-bg-white py-2' name={APP_SECTION_MESSAGE_CREATOR}>
                    {
                        isActualOwner(humanTask, user) && isTaskActive(humanTask) && !isThreadDeleted(thread) && !isThreadClosed(thread) ?
                            <MessageCreator/> : 
                            <InactiveMessageCreator isActualOwner={isActualOwner(humanTask, user) }
                                                    isTaskActive={isTaskActive(humanTask)}
                                                    isThreadDeleted={isThreadDeleted(thread)}
                                                    isThreadClosed={isThreadClosed(thread)}
                                                    isHumanTaskExpired={isHumanTaskExpired(humanTask)}/>
                    }
                </AppSectionContainer>
            :null}
        </>
    )
};

export default ApplicationMessageCreator;