import {
    ENVIRONMENT_PROPERTIES_ERROR,
    ENVIRONMENT_PROPERTIES_LOADED,
    ENVIRONMENT_PROPERTIES_LOADING
} from '../core/const'

const initialState = {
    properties: {},
    loading: false,
    error: false,
    dcsLoading: false,
    dcsError: false
};

const environmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ENVIRONMENT_PROPERTIES_LOADING:
            return {...state, loading: true, error: false};
        case ENVIRONMENT_PROPERTIES_LOADED:
            return {...state, properties: action.properties, loading: false, error: false};
        case ENVIRONMENT_PROPERTIES_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};

export default environmentReducer;