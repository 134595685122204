import {
    CANCEL_MODAL_TOGGLE,
    LAYOUT_UPDATE,
    MENU_TOGGLED,
    NOTES_TOGGLED,
} from "../core/const";

export const layoutUpdate = (component, height) => (
    {type: LAYOUT_UPDATE, component, height}
);

export const menuToggled = (open) => (
    {type: MENU_TOGGLED, open}
);

export const notesToggled = (open) => (
    {type: NOTES_TOGGLED, open}
);

export const toggleCancelModal = (open) => (
    {type: CANCEL_MODAL_TOGGLE, open}
);