/**
 * TASK OPERATIONS - numbers from https://docs.google.com/spreadsheets/d/1h_fHQEJliqy71SBqv__C3fLWMnTRAC3nnoUSSBLVamU/edit#gid=662893597
 **/

export const TASK_COMPLETE = 20;
export const TASK_COOPERATION = 4;
export const TASK_SUSPEND = 22;
export const TASK_HAND_OVER_KCP = 3;
export const TASK_HAND_OVER_AJ = 19;
export const TASK_HAND_OVER_HYPO = 23;
export const TASK_TAKE = 10;
export const TASK_RESTORE = 16;
export const TASK_REPLAN = 11;
export const TASK_WAKE_UP = 2;
export const CASE_DELEGATE = 6;
export const CASE_CANCEL = 0;

/**
 * TASK templates
 **/
export const TASK_TEXT_PATTERN_SLA = "${sla}";
export const TASK_TEXT_PATTERN_SUSPENDED = "${suspendedUntil}";
export const TASK_TEXT_CLIENT_SLA = "Klient čeká na odpověď do: " + TASK_TEXT_PATTERN_SLA;
export const TASK_TEXT_TASK_DONE = "Tato konverzace je již ukončena. Můžete prohlížet konverzaci nebo její poznámky";
export const TASK_TEXT_TASK_ACTIVE = "Konverzace je aktivní";
export const TASK_TEXT_TASK_SUBSTITUTE_KC = "Konverzace je předaná náhradnímu řešiteli (KC)";
export const TASK_TEXT_TASK_SUBSTITUTE_EN = "Konverzace je předaná náhradnímu řešiteli (anglicky mluvící tým)";
export const TASK_TEXT_TASK_SUBSTITUTE_HYPO = "Konverzace je předaná náhradnímu řešiteli (hypoteční tým)";
export const TASK_TEXT_TASK_BANKER_INITIALIZED = "Napište klientovi zprávu. Pokud odpoví, přijde Vám notifikace";
export const TASK_TEXT_TASK_SUSPENDED = "Konverzace je odložená do: " + TASK_TEXT_PATTERN_SUSPENDED;
export const TASK_TEXT_TASK_DELETED_BY_CLIENT = "Klient tuto konverzaci v George smazal. Už není možné odpovídat.";

/**
 * TASK servicing
 */
export const TASK_SERVICING_EP = ["5"];
export const TASK_SERVICING_EPB = ["9"];
export const TASK_SERVICING_EPB_EP = ["5", "9"];
export const TASK_SERVICING_OTHER = ["1","2","3","4","6","7","8","0"]; //MM & MA segments

export const TASK_DELEGATE_BO = "BO";
export const TASK_DELEGATE_BO_EN = "BO_EN";
export const TASK_DELEGATE_BO_HYPO = "BO_HYPO";

export const TASK_SOLVER_ALL_ROLES = [
    "SMART_MIT_solver_frontOffice",
    "SMART_MIT_solver_frontOfficeEP",
    "SMART_MIT_solver_frontOfficeEPB",
    "SMART_MIT_solver_backOffice",
    "SMART_MIT_solver_backOfficeEP",
    "SMART_MIT_solver_backOfficeEPB"
];

export const TASK_SOLVER_KC_ROLES = [
    "SMART_MIT_solver_backOffice",
    "SMART_MIT_solver_backOfficeEP",
    "SMART_MIT_solver_backOffice_EN"
];

export const TASK_SOLVER_FO_ROLES = [
    "SMART_MIT_solver_frontOffice",
    "SMART_MIT_solver_frontOfficeEP",
    "SMART_MIT_solver_frontOfficeEPB"
];

export const TASK_SOLVER_KC_CZ_ROLES = [
    "SMART_MIT_solver_backOffice",
    "SMART_MIT_solver_backOfficeEP"
];

export const TASK_SOLVER_KC_EN_ROLES = [
    "SMART_MIT_solver_backOffice_EN"
];

export const TASK_SOLVER_KC_HYPO_ROLES = [
    "SMART_MIT_solver_backOffice_HYPO"
];

export const TASK_SOLVER_ENABLE_TO_EN_DELEGATION_ROLES_FO = [
    "SMART_MIT_solver_frontOffice",
    "SMART_MIT_solver_frontOfficeEP"
];

export const TASK_SOLVER_ENABLE_TO_EN_DELEGATION_ROLES_BO = [
    "SMART_MIT_solver_backOffice",
    "SMART_MIT_solver_backOfficeEP"
];

export const TASK_SOLVER_EP_BANKER_ROLE = [
    "SMART_MIT_solver_frontOfficeEP"
]

export const HT_MIT_BO = "htMitBo"

/**
 * TASK client - user relation
 */
export const TASK_RELATIONS = [
    {servicingCategories: ['1','2','3','4','6','7','8'], role:'SMART_MIT_solver_frontOffice'},
    {servicingCategories: ['5'], role:'SMART_MIT_solver_frontOfficeEP'},
    {servicingCategories: ['9'], role:'SMART_MIT_solver_frontOfficeEPB'},
];

/**
 * TASK look
 */
export const TASK_ACTION_SKIN_BUTTON = 'button';
export const TASK_ACTION_SKIN_ITEM = 'dropdownItem';
