import { combineReducers } from "redux";
import messenger from "./messenger";
import authorization from "./authorization";
import user from "./user";
import humanTask from "./humanTask";
import layout from "./layout";
import alerts from "./alerts";
import environment from "./environment";

const rootReducer = combineReducers({
    messenger,
    authorization,
    user,
    humanTask,
    layout,
    alerts,
    environment
  });

  export default rootReducer;
