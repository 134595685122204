
import {Icon} from "@george-labs.com/design-system";

export const USER = {
    "TAG": "%USER%",
    "BACKUP": "Uživatel"
}

export const ACTION_TYPE = {
    THREAD_COMPLETE: {label: "Konverzace ukončena", icon: Icon.ICON.CHECKMARK_CIRCLE},
    DELEGATE_BO: {label: "Konverzace byla předaná na klientské centrum", icon: Icon.ICON.GROUP},
    DELEGATE_BO_EN: {label: "Konverzace předaná náhradímu řešiteli (anglicky mluvící tým)", icon: Icon.ICON.WORLD},
    DELEGATE_BO_HYPO: {label: "Konverzace předaná náhradímu řešiteli (hypoteční tým)", icon: Icon.ICON.HOME},
    DELEGATE_SLA: {label: "Konverzace přepadla pro nečinnost na náhradního řešitele", icon: Icon.ICON.CLOCK},
    DELEGATE_CASE: {label: "Konverzace předána z collections", icon: Icon.ICON.BANK},
    COOP: {label: "Vyžádána pomoc podpory", icon: Icon.ICON.HELP},
    THREAD_COMPLETE_OTHER: {label: "Konverzace ukončena jiným kanálem", icon: Icon.ICON.PHONE_HOTLINE},
    TAKEOVER: {label: USER.TAG + " převzal/a řešení", icon: Icon.ICON.REDO},
    TAKE: {label: USER.TAG + " zahájil/a řešení", icon: Icon.ICON.MEDIA_PLAY},
    SUSPEND: {label: USER.TAG + " odložil řešení", icon: Icon.ICON.CLOCK},
    THREAD_COMPLAIN: {label: USER.TAG + " obnovil/a konverzaci", icon: Icon.ICON.STANDING_ORDER},
    NOTE: {label: "Uživatel přidal poznámku", icon: Icon.ICON.EDIT},
    SUSPEND_INTERRUPT: {label: USER.TAG + " zrušil odložení", icon: Icon.ICON.CALENDAR},
    THREAD_DELETED_CLIENT: {label: "Klient smazal konverzaci, už do ní nejde psát", icon: Icon.ICON.DELETE}
};

export const CB_ServicingCategoryManual = {
    "1": "1_s obsluhou",
    "2": "2_poradenství",
    "3": "3_privátní poradenství",
    "4": "4_mobilní poradenství",
    "5": "Premier",
    "6": "Standard",
    "7": "Plus",
    "8": "D4 Individuální obsluha",
    "9": "EPB",
    "10": "Nedefinováno"
};

export const DOCUMENT_CHECK_STATE_CZ = {
    AV_NOT_FOUND: "Soubor nebyl nalezen. Požádejde prosím klienta o opětovné zaslání.",
    CHECKED: "Zkontrolováno",
    CHECKING: "Probíhající kontrola",
    COMPLETED: "Dokončeno",
    DATA_ID_NOT_EQUALS: "Identifikátor souboru, a identifikátor v antiviru se liší",
    DMS_DOCUMENT_ERROR: "Během ukládání dokumentu došlo k chybě",
    ERROR: "Chyba",
    INFECTED: "Soubor je zavirovaný",
    MISSING_RESULT: "Soubor nebyl nalezen. Požádejde prosím klienta o opětovné zaslání.",
    NO_PROGRESS_FOUND: "Soubor nebyl nalezen. Požádejde prosím klienta o opětovné zaslání.",
    PASSWORD_PROTECTED: "Soubor je chráněn heslem. Je potřeba, aby klient poslal dokument nezaheslovaný.",
    STATUS_ERROR: "Soubor nebyl nalezen. Požádejde prosím klienta o opětovné zaslání.",
    UNSUPPORTED: "Soubor je v nepodporovaném formátu",
    UPLOADED: "Nahrán",
};

export const ITEM_TYPE = {
    MESSAGE: "MESSAGE",
    ACTION: "ACTION"
};