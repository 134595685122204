import React from "react";
import BlockingModal from "./blockingModal";

const AppLoadingModal = () => {

    return (
        <BlockingModal isOpen={true} loading={true} loadingText='Načítáme konverzaci s klientem' title='Vítejte'/>
    )
};

export default AppLoadingModal