import Col from "reactstrap/es/Col";
import {resendMessage} from "../../../actions/messengerActions";
import {Avatar, Card, Icon, Text} from "@george-labs.com/design-system";
import Row from "reactstrap/es/Row";
import parse from "html-react-parser";
import AttachmentItem from "./attachmentItem";
import MessageTooltip from "./messageTooltip";
import React from "react";
import {useDispatch} from "react-redux";

const BankerMessage = ({message, handleDownloadDocument}) => {

    const dispatch = useDispatch();

    const handleResendMessage = () => {
        dispatch(resendMessage(message));
    };

    const handleDownload = (doc) => {
        handleDownloadDocument(doc)
    };

    const resolveBankerNameFromMessage = (message) => {
        return message.forename && message.surname ?
            message.forename + " " + message.surname : "";
    }

    return (
        <Row key={message.id} className="mb-2">
            <Col className="px-0">
                {/* do not remove, this is handy and important placeholder */}
            </Col>
            <Col xs={9} sm={10} md={8} className="pr-0">
                <Card className="outgoing-message my-0" color={Card.COLOR.BLUE_300}>
                    <Row>
                        <Col>
                            <span className="messageHref">
                                <Text>{message.body ? parse(message.body) : ""}</Text>
                            </span>
                        </Col>
                    </Row>
                    {message.attachments?.map(a => {
                        return <AttachmentItem key={a.uuid}
                                               attachment={a}
                                               handleDownload={handleDownload}
                                               isOutgoing={true}/>
                    })}
                    <Row className="p-0">
                        <Col className="text-right">
                            <MessageTooltip message={message}
                                            text={resolveBankerNameFromMessage(message)}
                                            isOutgoing={true}
                                            handleResendMessage={handleResendMessage}/>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col className="col-auto align-items-end d-flex px-0 my-3">
                <div className='arrow-right'/>
            </Col>
            <Col className="col-auto align-items-end d-flex pl-2">
                <Avatar icon={Avatar.ICON.USER} color={Avatar.COLOR.WHITE} backgroundColor={Avatar.COLOR.BLUE_300}/>
            </Col>
        </Row>
    )
};

export default BankerMessage