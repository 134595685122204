import React from 'react';
import { useSelector } from "react-redux";
import ClientMessage from "./clientMessage";
import BankerMessage from "./bankerMessage";

const Message = (props) => {
    const {message, handleDownloadDocument} = props;
    let isBanker = props.message.username;
    const client = useSelector(state => state?.messenger?.client || {});
    const {source} = message || {};

    return (
        <>
            {!isBanker ?
                <ClientMessage client={client} source={source} message={message} handleDownloadDocument={handleDownloadDocument} isLast={props.isLast}/>: null}

            {isBanker ?
                <BankerMessage message={message} handleDownloadDocument={handleDownloadDocument} isLast={props.isLast}/> : null}
        </>
    )
};

export default Message;