import React from "react";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import {Icon} from "@george-labs.com/design-system";

const CopyIcon = ({text, size = Icon.SIZE.SIZE_24}) => {
    const {isCopied, handleCopy, errorMessage} = useCopyToClipboard(3000);

    const getTitle = () => {
        if (errorMessage) {
            return errorMessage;
        }

        if (isCopied) {
            return "Uspěšně zkopírováno";
        }

        return "Kopírovat do schránky";
    };

    const getCopyUI = () => {
        if (errorMessage) {
            return <Icon icon={Icon.ICON.ERROR} color={Icon.COLOR.RED_500} size={size}/>;
        }

        if (isCopied) {
            return <Icon icon={Icon.ICON.CHECKMARK} size={size}/>
        }

        return <Icon icon={Icon.ICON.COPY} color={Icon.COLOR.BLUE_300} size={size}/>;
    };

    return (
        <span className="selectable ml-2" onClick={() => handleCopy(text)} title={getTitle()}>
            {getCopyUI()}
        </span>
    );
};

export default CopyIcon;