import {useCallback, useEffect, useState} from "react";
import copy from "copy-to-clipboard";

export default function useCopyToClipboard(resetInterval = null) {
    const [isCopied, setCopied] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleCopy = useCallback((text) => {
        if (typeof text === "string" || typeof text == "number") {
            copy(text.toString(), {format: "text/plain"});
            setCopied(true);
        } else {
            setCopied(false);
            let msg = `Hodnotu z ${typeof text} nelze zkopírovat do schránky.`;
            console.error(msg);
            setErrorMessage(msg);
        }
    }, []);

    useEffect(() => {
        let timeout;
        if ((isCopied || errorMessage) && resetInterval) {
            timeout = setTimeout(() => {
                setCopied(false);
                setErrorMessage(null);
            }, resetInterval);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isCopied, resetInterval, errorMessage, setErrorMessage]);

    return {isCopied, handleCopy, errorMessage};
}