import ActionStripeButton from "./actionStripeButton";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionsBasicModal from "../../comp/modals/actionsBasicModal";
import {
    CASE_CANCEL,
    CASE_DELEGATE,
    TASK_COMPLETE, TASK_DELEGATE_BO, TASK_DELEGATE_BO_EN, TASK_DELEGATE_BO_HYPO,
    TASK_HAND_OVER_AJ, TASK_HAND_OVER_HYPO,
    TASK_HAND_OVER_KCP, TASK_RESTORE,
    TASK_TAKE, TASK_WAKE_UP
} from "./utils/actionStripeConst";
import {Button, SmallModal} from "@george-labs.com/design-system";
import {
    fireCancel,
    fireClaimAndTake,
    fireComplete,
    fireDelegateCase,
    takeHumanTask
} from "../../actions/humanTaskActions";
import ActionsRestoreModal from "../modals/actionsRestoreModal";
import ActionsDelegateModal from "../modals/actionsDelegateModal";
import {isActualOwner} from "./utils/actionStripeConfig";
import {Col, Row} from "reactstrap";
import {toggleCancelModal} from "../../actions/layoutAction";

export const ActionStripeButtons = ({config}) => {
    const cidla = useSelector(state => state?.messenger?.cidla);
    const {humanTask, actualOwnerFullName} = useSelector(state => state?.humanTask || {});
    const {actualOwner} = useSelector(state => state?.humanTask?.humanTask || {});
    const user = useSelector(state => state?.user?.identity);
    const firstHistoryEvent = useSelector(state => state.messenger.history || []);

    const [modalConfig, setModalConfig] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [delegateKcModalOpen, setDelegateKcModalOpen] = useState(false);
    const [delegateHypoModalOpen, setDelegateHypoModalOpen] = useState(false);
    const [delegateEnModalOpen, setDelegateEnModalOpen] = useState(false);
    const [restoreModalOpen, setRestoreModalOpen] = useState(false);
    const [processedConfig, setProcessedConfig] = useState({});
    const [completeEnabled, setCompleteEnabled] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (config) {
            setProcessedConfig(buildBtnConfig())
        }
    }, [config]);

    const handleTake = (noaction) => {
        if (!isActualOwner(humanTask, user)) {
            return dispatch(fireClaimAndTake(cidla, noaction)).then(() => setModalOpen(false));
        } else {
            return dispatch(takeHumanTask(cidla, true)).then(() => setModalOpen(false))
        }
    };

    const handleCancel = () => {
        return dispatch(fireCancel(cidla))
    };

    const openModal = (header, body, primary, secondary, back, color) => {
        setModalOpen(true);
        setModalConfig({
            header: header,
            body: body,
            primary: primary,
            secondary: secondary,
            back: back
        })
    };

    const getClaimText = (bodyText) => {
        return bodyText.replace("$RESITEL$", actualOwner).replace("$FULLNAME$", actualOwnerFullName);
    };

    const handleComplete = () => {
        setCompleteEnabled(false);
        dispatch(fireComplete(cidla, {completeType: "message"}))
            .then(() => setCompleteEnabled(true))
            .catch(() => setCompleteEnabled(true))
    }

    const completeBtn = (variant, className) => {
        return (<ActionStripeButton label="Hotovo"
                                    icon={Button.ICON.CHECKMARK}
                                    disabled={!completeEnabled}
                                    variant={variant}
                                    className={className}
                                    key={TASK_COMPLETE}
                                    title="Zavíráte konverzaci"
                                    onClick={handleComplete}/>)
    };

    const handOverKcp = (variant, className) => {
        return (<ActionStripeButton label="Klientské centrum"
                                    variant={variant}
                                    className={className}
                                    key={TASK_HAND_OVER_KCP}
                                    title="Předáváte konverzaci"
                                    onClick={() => setDelegateKcModalOpen(true)}/>)
    };

    const handOverAj = (variant, className) => {
        return (<ActionStripeButton label="Anglický tým"
                                    variant={variant}
                                    className={className}
                                    key={TASK_HAND_OVER_AJ}
                                    title="Předáváte konverzaci"
                                    roles={["SMART_MIT_solver_frontOffice"]}
                                    onClick={() => setDelegateEnModalOpen(true)}/>)
    };

    const caseDelegateBtn = (variant, className) => {
        return (<ActionStripeButton label="Bankéře"
                                    variant={variant}
                                    className={className}
                                    key={CASE_DELEGATE}
                                    title="Předáváte konverzaci"
                                    roles={["SMART_MIT_solver_collections"]}
                                    onClick={() => openModal(
                                        'Předáváte konverzaci',
                                        'PŘEDÁNÍ KONVERZACE OSOBNÍMU BANKÉŘI - Předejte konverzaci, pokud se týká jiného tématu. Konverzace se přepošle osobnímu bankéři klienta, který řešení převezme.',
                                        {
                                            label: 'Ano, předat na bankéře',
                                            onClick: () => dispatch(fireDelegateCase(cidla)).then(setModalOpen(false))
                                        },
                                        null,
                                        {label: 'Ne, pokračovat v konverzaci', onClick: () => setModalOpen(false)}
                                    )}/>)
    }


    const takeBtn = (variant, className) => {
        return (<ActionStripeButton label="Převzít řešení"
                                    variant={variant}
                                    className={className}
                                    key={TASK_TAKE}
                                    title="Přebíráte řešení"
                                    onClick={() => openModal(
                                        'Převzít řešení',
                                        getClaimText('Chystáte se převzít řešení konverzace s klientem, kterou aktuálně řeší $RESITEL$ / $FULLNAME$. Chcete se stát novým řešitelem?'),
                                        {
                                            label: 'Ano, budu řešit komunikaci s klientem',
                                            onClick: () => handleTake(firstHistoryEvent.length > 0)
                                        },
                                        null,
                                        {label: 'Ne, vrátit se na přehled', onClick: () => setModalOpen(false)}
                                    )}/>)
    };


    const handOverHypo = (variant, className) => {
        return (<ActionStripeButton label="Hypoteční tým"
                                    variant={variant}
                                    className={className}
                                    key={TASK_HAND_OVER_HYPO}
                                    title="Předáváte konverzaci"
                                    onClick={() => setDelegateHypoModalOpen(true)}/>)
    };

    const wakeBtn = (variant, className) => {
        return (<ActionStripeButton label="Zrušit odložení"
                                    icon={Button.ICON.MEDIA_PLAY}
                                    variant={variant}
                                    className={className}
                                    key={TASK_WAKE_UP}
                                    title="Zrušit odložení"
                                    onClick={() => openModal(
                                        'Zrušit odložení',
                                        'Opravdu chcete zrušit odložení konverzace s klientem?',
                                        {label: 'Ano, zrušit', onClick: () => handleTake(false)},
                                        null,
                                        {label: 'Ne, vrátit se na přehled', onClick: () => setModalOpen(false)}
                                    )}/>)
    };

    const restoreBtn = (variant, className) => {
        return (<ActionStripeButton label="Obnovit konverzaci"
                                    icon={Button.ICON.RELOAD}
                                    variant={variant}
                                    className={className}
                                    key={TASK_RESTORE}
                                    title="Obnovení konverzace"
                                    onClick={() => setRestoreModalOpen(true)}/>);
    };

    const cancelBtn = (variant, className) => {
        return (<ActionStripeButton label="Storno konverzace"
                                    variant={variant}
                                    className={className}
                                    key={CASE_CANCEL}
                                    title="Storno konverzace"
                                    onClick={() => dispatch(toggleCancelModal(true))}/>)
    };

    const getButton = (target, variant, className) => {
        switch (target) {
            case TASK_COMPLETE:
                return completeBtn(variant, className);
            case TASK_HAND_OVER_KCP:
                return handOverKcp(variant, className);
            case TASK_HAND_OVER_AJ:
                return handOverAj(variant, className);
            case TASK_TAKE:
                return takeBtn(variant, className);
            case TASK_WAKE_UP:
                return wakeBtn(variant, className);
            case TASK_RESTORE:
                return restoreBtn(variant, className);
            case TASK_HAND_OVER_HYPO:
                return handOverHypo(variant, className);
            case CASE_DELEGATE:
                return caseDelegateBtn(variant, className);
            case CASE_CANCEL:
                return cancelBtn(Button.VARIANT.SECONDARY, className);
        }
    };

    const hasPrimaryButtons = () => {
        return config && config.buttons && config.buttons.some(b => b.primary)
    };

    const hasHandOverButton = () => {
        return config && config.buttons && config.buttons.some(b => b.handOver)
    };

    const hasAnyButtons = () => {
        return config && config.buttons && config.buttons.length
    };

    const getPrimaryButton = () => {
        if (!config.buttons || !config.buttons.length) {
            return null;
        }

        if (hasPrimaryButtons()) {
            return config.buttons.filter(b => b.primary).sort(compare)[0]
        }

        if (!hasPrimaryButtons()) {
            return config.buttons.filter(b => !b.primary).sort(compare)[0]
        }
    };

    const buildBtnConfig = () => {
        if (!hasAnyButtons()) {
            return {primary: null, secondary: []}
        }

        let primary = getPrimaryButton();
        let secondary = config.buttons.filter(b => b.id !== primary.id).sort(compare);

        return {primary, secondary};
    };

    function compare(a, b) {
        if (a.importance < b.importance)
            return -1;
        if (a.importance > b.importance)
            return 1;
        return 0;
    }

    return (
        <>
            {processedConfig && processedConfig.primary ?
                <>
                    <Row>
                        <Col className="text-center">
                            {getButton(processedConfig.primary.id, Button.VARIANT.PRIMARY, null)}
                        </Col>
                    </Row>
                    {processedConfig.secondary.filter(b => !b.handOver).map((b, idx) =>
                        <Row key={b.id}>
                            <Col className="text-center">
                                {getButton(b.id, Button.VARIANT.SECONDARY, idx !== 0 ? 'border-top-0' : '')}
                            </Col>
                        </Row>
                    )}
                    {hasHandOverButton() ?
                        <Row>
                            <Col className="text-center">
                                <h3 className="mt-0 mb-2">Předat na</h3>
                            </Col>
                        </Row> : null}
                    {processedConfig.secondary.filter(b => b.handOver).map((b, idx) =>
                        <Row key={b.id}>
                            <Col className="text-center">
                                {getButton(b.id, Button.VARIANT.SECONDARY, idx !== 0 ? 'border-top-0' : '')}
                            </Col>
                        </Row>
                    )}
                </> : null}

            {modalConfig ?
                <>
                    <ActionsBasicModal isOpen={modalOpen} onToggle={() => setModalOpen(false)} config={modalConfig}/>
                </>
                : null}

            <ActionsDelegateModal
                isOpen={delegateKcModalOpen}
                onToggle={() => setDelegateKcModalOpen(false)}
                header={"Předání na klientské centrum"}
                delegationType={TASK_DELEGATE_BO}
                submitText="Ano, předat na Klientské centrum"/>

            <ActionsDelegateModal
                isOpen={delegateEnModalOpen}
                onToggle={() => setDelegateEnModalOpen(false)}
                header={"Předání na anglický tým"}
                delegationType={TASK_DELEGATE_BO_EN}
                submitText="Ano, předat na anglický tým"/>

            <ActionsDelegateModal
                isOpen={delegateHypoModalOpen}
                onToggle={() => setDelegateHypoModalOpen(false)}
                header={"Předání na hypoteční tým"}
                delegationType={TASK_DELEGATE_BO_HYPO}
                submitText="Ano, předat na hypoteční tým"/>

            <ActionsRestoreModal
                isOpen={restoreModalOpen}
                onToggle={() => setRestoreModalOpen(false)}/>
        </>
    );
};

export default ActionStripeButtons;
