import MessengerModal from "./messengerModal";
import React, {useState} from "react";
import {Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import {SmartGroupVertical, SmartTextarea} from "@csas-smart/smart-ui-comps";
import {useDispatch, useSelector} from "react-redux";
import {fireDelegate} from "../../actions/humanTaskActions";
import ActionsModalFooter from "./actionsModalFooter";

export const ActionsDelegateModal = ({isOpen, onToggle, delegationType, header, submitText}) => {

    const [note, setNote] = useState('');

    const delegating = useSelector(state => state?.humanTask?.delegating);
    const cidla = useSelector(state => state?.messenger?.cidla);
    const dispatch = useDispatch();

    const handleSubmit = () => {
        dispatch(fireDelegate(cidla, {note:note, delegationType: delegationType})).then(() => onToggle());
    };

    const getBody = () => {
        return (
            <>
                <Row>
                    <Col>
                        <SmartGroupVertical id="sgv-note" label="Poznámka pro příjemce"
                                            target="st-note">
                            <SmartTextarea rows={3}
                                           id="st-note"
                                           value={note}
                                           onChange={e => setNote(e.target.value)}/>
                        </SmartGroupVertical>
                    </Col>
                </Row>
            </>
        )
    };

    const getFooter = () => {
        let primary = {
            onClick: handleSubmit,
            text: submitText
        };

        let cancel = {
            onClick: onToggle,
            text: 'Ne, pokračovat v konverzaci'
        };

        return (
            <ActionsModalFooter primary={primary} cancel={cancel}/>
        )
    };

    return (
        <MessengerModal
            size="xs"
            processing={delegating.progress}
            processingText='Předávám, počkejte prosím'
            error={delegating.error}
            isOpen={isOpen}
            onToggle={onToggle}
            header={header}
            body={getBody()}
            footer={getFooter()}
        />
    )
};

export default ActionsDelegateModal