import {Container} from "reactstrap";
import React, {useEffect, useRef} from "react";
import * as PropTypes from "prop-types";
import {layoutUpdate} from "../../../actions/layoutAction";
import {useDispatch} from "react-redux";
import {useResizeDetector} from "react-resize-detector";

const AppSectionContainer = (props) => {

    const {className, name, full, id, style} = props;
    const {height, ref} = useResizeDetector();
    const dispatch = useDispatch();

    useEffect(() => {
        if(height) {
            dispatch(layoutUpdate(name, height))
        }

        return () => dispatch(layoutUpdate(name, 0))
    }, [height]);

    const getContainerClassNames = (additionalClassNames) => {
        const classNames = [];
        classNames.push('container-fluid');
        classNames.push(className ? className : '');
        classNames.push(additionalClassNames ? additionalClassNames : '');
        return classNames.join(' ');
    };

    return (
        <div className={getContainerClassNames()} ref={ref} id={id} style={style}>
            {!full ?
                <Container className='h-100'>
                    {props.children}
                </Container> : null}

            {full ?
                props.children : null}
        </div>
    )
};

AppSectionContainer.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    full: PropTypes.bool
};

export default AppSectionContainer;