import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Container, Row, Col} from "reactstrap";
import {Icon, Paragraph} from "@george-labs.com/design-system";
import {menuToggled, notesToggled} from "../../actions/layoutAction";
import ActionStripeButtons from "../stripe/actionStripeButtons";
import {findConfiguration} from "../stripe/utils/actionStripeConfig";
import {setClientDetailOpen} from "../../actions/messengerActions";
import ActionStripeState from "../stripe/actionStripeBadge";
import {APP_SECTION_HEADER, APP_TOTAL_HEIGHT} from "../../core/const";

const ApplicationMenu = () => {
    const menuOpen = useSelector(state => state.layout.menuOpen);
    const notesOpen = useSelector(state => state.layout.notesOpen);
    const thread = useSelector(state => state?.messenger?.thread);
    const client = useSelector(state => state?.messenger?.client);
    const user = useSelector(state => state?.user?.identity);
    const humanTask = useSelector(state => state?.humanTask?.humanTask);
    const messageCount = useSelector(state => state?.messenger?.messageCount);
    const applicationHeight = useSelector(state => state.layout[APP_TOTAL_HEIGHT]);
    const applicationHeader = useSelector(state => state.layout[APP_SECTION_HEADER]);

    const [config, setConfig] = useState(null);
    const [style, setStyle] = useState({
        height: '100vh'
    });

    const dispatch = useDispatch();

    useEffect(() => {
        let config = findConfiguration(humanTask, thread, client, user);
        setConfig(config)
    }, [humanTask?.actualOwner, humanTask?.taskStatus, messageCount, client, thread?.deletedAt]);

    useEffect(() => {
        if(applicationHeight && applicationHeader) {
            setStyle({...style, height: (applicationHeight - applicationHeader) + 'px'})
        }
    }, [applicationHeight, applicationHeader]);

    return (
        <>
            {menuOpen ?
                <Container fluid className='right-menu fixed-top fixed-bottom' style={{top: (applicationHeader) + 'px'}}>
                    <Row style={style}>
                        <Col md={6} lg={9} className="d-none d-sm-block backdrop" onClick={() => dispatch(menuToggled(!menuOpen))} >
                        </Col>
                        <Col xs={12} md={6} lg={3} className="bg-white border-left h-100 d-flex flex-column">
                            <Row className="mt-2">
                                <Col className="d-sm-block d-md-none">
                                    <ActionStripeState config={config} inMenuSection={true}/>
                                </Col>
                                <Col xs={6} md={12} className="text-right col-sm-auto">
                                    <Paragraph onClick={() => dispatch(menuToggled(!menuOpen))} className='selectable'>
                                        <Icon icon={Icon.ICON.ERROR} color={Icon.COLOR.BLUE_300}
                                              size={Icon.SIZE.SIZE_24}/>
                                        <span className='ml-1 text-primary font-weight-bold'>
                                        Zavřít
                                    </span>
                                    </Paragraph>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <ActionStripeButtons config={config}/>
                                <Row>
                                    <Col onClick={() => dispatch(notesToggled(!notesOpen))} className="text-center selectable">
                                        <Paragraph>
                                            <Icon icon={Icon.ICON.EDIT} color={Icon.COLOR.BLUE_300} size={Icon.SIZE.SIZE_24}/>
                                            <span className='text-primary font-weight-bold ml-3'>
                                                Poznámky
                                            </span>
                                        </Paragraph>
                                    </Col>
                                </Row>
                            </Row>
                            <Row className="flex-grow-1 position-relative">
                                <Col onClick={() => dispatch(setClientDetailOpen(true))} className="text-center selectable mt-auto mb-4">
                                    <Paragraph>
                                        <Icon icon={Icon.ICON.USER} color={Icon.COLOR.BLUE_300} size={Icon.SIZE.SIZE_24}/>
                                        <span className='text-primary font-weight-bold ml-1'>
                                            Informace o klientovi
                                        </span>
                                    </Paragraph>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container> : null}
        </>
    )
};

export default ApplicationMenu;