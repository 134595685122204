import {Col, Row} from "reactstrap";
import {Button, Icon, TextareaInput, Card, Text, Paragraph} from "@george-labs.com/design-system";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addNote} from "../../../actions/messengerActions";
import {
    APP_SECTION_ADD_NOTE_BUTTON,
    APP_SECTION_HEADER,
    APP_SECTION_NOTE_BUTTONS,
    APP_TOTAL_HEIGHT,
    DEFAULT_MAX_NOTE_HEIGHT,
    DEFAULT_NOTE_BODY_HEIGHT,
    NOTE_ACTION_TYPE
} from "../../../core/const";
import {generateFormatDate, sortBy} from "../../../core/utils";

const NotesBody = ({updateMode, updateModeHandler}) => {

    const history = useSelector(state => state.messenger.history || []);
    const [newNote, setNewNote] = useState("");
    const applicationHeight = useSelector(state => state.layout[APP_TOTAL_HEIGHT]);
    const applicationHeader = useSelector(state => state.layout[APP_SECTION_HEADER]);
    const applicationNoteButtons = useSelector(state => state.layout[APP_SECTION_NOTE_BUTTONS]);
    const applicationNoteAddButton = useSelector(state => state.layout[APP_SECTION_ADD_NOTE_BUTTON]);

    const [height, setHeight] = useState(DEFAULT_NOTE_BODY_HEIGHT);
    const [maxNoteHeight, setMaxNoteHeight] = useState(DEFAULT_MAX_NOTE_HEIGHT);

    useEffect(() => {
        if (applicationHeight && applicationHeader && applicationNoteButtons && (applicationNoteAddButton || applicationNoteAddButton === 0)) {
            setHeight(applicationHeight - applicationHeader - applicationNoteButtons - applicationNoteAddButton)
        }
        if (updateMode && applicationHeight && applicationHeader && applicationNoteButtons) {
            setMaxNoteHeight(applicationHeight - applicationHeader - applicationNoteButtons - 150)
        }
    }, [applicationHeight, applicationHeader, applicationNoteButtons, applicationNoteAddButton, updateMode]);

    useEffect(() => {
        if (!updateMode) {
            setNewNote("");
        }
    }, [updateMode]);

    const dispatch = useDispatch();

    const atLeastOneNoteInHistory = () => {
        return history?.some(item => item.actionType === NOTE_ACTION_TYPE);
    }

    const hasNotes = () => {
        return history && history.length > 0 && atLeastOneNoteInHistory();
    };

    const isEmptyNote = () => {
        return !newNote;
    }

    const addNewNote = () => {
        dispatch(addNote(newNote));
        setNewNote("");
        updateModeHandler(false);
    }

    const renderNotes = () => {
        let notes = history?.filter(item => item.actionType === NOTE_ACTION_TYPE);
        const sortedNotes = sortBy(notes, "createdAt").reverse();

        return (
            <Row>
                {sortedNotes?.map((item) => {
                    return <Card className="mt-2 ml-2 mr-2 pt-2 pl-2 pr-2 border-2" key={item.uuid + "note"}>
                        <Row>
                            <Text className="p-0 m-0 text-secondary">{item.note}</Text>
                        </Row>
                        <Row className="pt-1 justify-content-end">
                            <Paragraph small faded>{item.forename} {item.surname} <Icon icon={Icon.ICON.UNREAD}
                                                                                        color={Icon.COLOR.GREY_500}
                                                                                        size={Icon.SIZE.SIZE_16}/> {generateFormatDate(item.createdAt, "DD.MM.YYYY HH:mm")}
                            </Paragraph>
                        </Row>
                    </Card>
                })}
            </Row>
        )
    }

    const resolveNotesList = () => {
        return (
            <>
                {hasNotes() ?
                    renderNotes()
                    :
                    <Row className='h-100 d-flex'>
                        <Col className="my-auto text-center">
                            <Icon icon={Icon.ICON.EMPTY_STATE_GENERIC} color={Icon.COLOR.GREY_500}
                                  size={Icon.SIZE.SIZE_48}/>
                            <h3>Zatím bez poznámek</h3>
                        </Col>
                    </Row>
                }
            </>
        )
    }

    return (
        <Row className='notesBody' style={{height: height + 'px'}}>
            <Col>
                {updateMode ?
                    <Row>
                        <TextareaInput type="text" rows={4} name="newNote" className="mt-4 ml-2 mr-2"
                                       maxHeight={maxNoteHeight} value={newNote}
                                       onChange={e => setNewNote(e.target.value)}/>
                        <Button className="mt-3 mr-2 ml-2" fullWidth={Button.FULL_WIDTH.ALWAYS}
                                disabled={isEmptyNote()}
                                onClick={addNewNote}>
                            Uložit
                        </Button>
                    </Row>
                    : resolveNotesList()
                }
            </Col>
        </Row>
    )
}

export default NotesBody;