import Row from "reactstrap/es/Row";
import { ACTION_TYPE, USER } from "../../../core/enums";
import React from "react";
import BadgeComp from "./badgeComp";

const Action = (props) => {

    const { action } = props;

    const resolveName = () => {
        return action.forename && action.surname ?
            action.forename + " " + action.surname : USER.BACKUP;
    }

    const resolveBadgeLabel = () => {
        let label = ACTION_TYPE[action.actionType]?.label;
        if (label?.includes(USER.TAG)) {
            label = label.replace(USER.TAG, resolveName())
        }
        return label;
    }

    return <Row>
            <BadgeComp label={resolveBadgeLabel()}/>
        </Row>
}

export default Action;