import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import { getUserFullName } from "../../actions/humanTaskActions";
import {TASK_TEXT_TASK_DELETED_BY_CLIENT} from "../stripe/utils/actionStripeConst";


const InactiveMessageCreator = ({isActualOwner, isTaskActive, isThreadDeleted, isThreadClosed, isHumanTaskExpired}) => {

    const dispatch = useDispatch();
    const { actualOwner } = useSelector(state => state?.humanTask?.humanTask || {});
    const actualOwnerFullName = useSelector(state => state?.humanTask?.actualOwnerFullName || "");

    useEffect(() => {
        if(actualOwner){
            dispatch(getUserFullName(actualOwner)).catch(() => console.log('Loading Actual Owner Fullname failed..'));
        }
    }, [actualOwner]);  

    return <Row className="mb-4 mt-2 justify-content-center">
        <Col className="col-md-auto">
            { isThreadDeleted && <p>{TASK_TEXT_TASK_DELETED_BY_CLIENT}</p> }
            { isThreadClosed && !isThreadDeleted && <p>Konverzace je uzavřená. Už není možné odpovídat.</p> }
            { !isActualOwner && !isThreadClosed && !isThreadDeleted && <p>Konverzace je přiřazená uživateli  {actualOwnerFullName}. Pokud chcete klientovi odpovědět, převezměte konverzaci.</p> }
            { !isTaskActive && !isThreadClosed && !isThreadDeleted && isHumanTaskExpired && <p>Konverzace není vyřešená. Pokud chcete klientovi odpovědět nebo konverzaci uzavřít, obnovte konverzaci.</p> }
            { !isTaskActive && !isThreadClosed && !isThreadDeleted && !isHumanTaskExpired && <p>Konverzace je ukončená. Pokud chcete klientovi odpovědět, obnovte konverzaci.</p> }
        </Col>
    </Row>
}

export default InactiveMessageCreator;