import {ALERT_FLOATING_CLOSE_DURATION, ALERT_HIDE, ALERT_SHOW} from "../core/const";

export const hideFloatingAlert = (alert) => (
    {type: ALERT_HIDE, alert}
);

export const showFloatingAlert = (alert) => (
    {type: ALERT_SHOW, alert}
);

export function fireShowAlert(label, color, timeout) {
    return (dispatch) => {
        let alertFloatingDto = {label, color, id: randomId(), timeout: timeout ? timeout : ALERT_FLOATING_CLOSE_DURATION};
        setTimeout(() => {
            dispatch(hideFloatingAlert(alertFloatingDto));
        }, alertFloatingDto.timeout);

        return dispatch(showFloatingAlert(alertFloatingDto));
    }
}

export function fireHideAlert(alert) {
    return (dispatch) => {
        return dispatch(hideFloatingAlert(alert));
    }
}

export function fireShowCountdownAlert(label, color, expireTime, onClick, actionLabel){
    return (dispatch) => {
        let countExpireTimeout = expireTime.getTime() - new Date().getTime();
        let alertFloatingDto = {label, color, id: randomId(), timeout: countExpireTimeout, onClick, actionLabel};
        setTimeout(() => {
            dispatch(hideFloatingAlert(alertFloatingDto));
        }, alertFloatingDto.timeout);

        return dispatch(showFloatingAlert(alertFloatingDto));
    }
}

export const randomId = () => {
    return Math.random().toString(36).slice(-5);
};