import Resource from "../core/serverResource";
import {
    ENVIRONMENT_PROPERTIES_ERROR,
    ENVIRONMENT_PROPERTIES_LOADED,
    ENVIRONMENT_PROPERTIES_LOADING
} from "../core/const";

export const environmentPropertiesLoading = () => (
    {type: ENVIRONMENT_PROPERTIES_LOADING}
);

export const environmentPropertiesLoaded = (properties) => (
    {type: ENVIRONMENT_PROPERTIES_LOADED, properties}
);

export const environmentPropertiesError = (error) => (
    {type: ENVIRONMENT_PROPERTIES_ERROR, error}
);

export function fireLoadEnvironment() {
    return (dispatch) => {
        dispatch(environmentPropertiesLoading());
        return fetch(Resource.getMitApi() + "/configuration")
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(properties => {
                dispatch(environmentPropertiesLoaded(properties));
                return Promise.resolve();
            })
            .catch(err => {
                dispatch(environmentPropertiesError(err.message));
                console.log("Unable to load properties", err)
                return Promise.reject(err);
            });
    }
}



