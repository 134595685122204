import React from "react";
import {Modal, ModalBody, Row, Col} from "@george-labs.com/design-system";

const BlockingModal = (props) => {

    return (
        <Modal size="xl" className="blocking-layer" isOpen={props.isOpen} footer={props.footer} title={props.title} closeable={false} isLoading={props.loading} loadingText={props.loadingText}>
            <ModalBody>
                <Row className="mt-5 mb-4">
                    <Col>
                        {props.children}
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
};

export default BlockingModal;