import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
    APP_SECTION_CONTENT,
    APP_SECTION_HEADER,
    APP_SECTION_MESSAGE_CREATOR,
    APP_SECTION_SUBHEADER, APP_TOTAL_HEIGHT
} from "../../core/const";
import AppSectionContainer from "./container/appSectionContainer";
import ThreadCanvas from "../thread/threadCanvas";
import EmptyCenterCanvas from "./emptyCenterCanvas";
import ExitedCenterCanvas from "./exitedCenterCanvas";

const ApplicationContent = () => {
    const subject = useSelector(state => state.messenger?.mergedSubject || "");
    const taskStatus = useSelector(state => state.humanTask?.humanTask?.taskStatus);
    const layoutMessageCreatorHeight = useSelector(state => state.layout[APP_SECTION_MESSAGE_CREATOR]);
    const layoutHeaderHeight = useSelector(state => state.layout[APP_SECTION_HEADER]);
    const layoutSubheaderHeight = useSelector(state => state.layout[APP_SECTION_SUBHEADER]);
    const layoutTotalHeight = useSelector(state => state.layout[APP_TOTAL_HEIGHT]);
    const [style, setStyle] = useState();

    useEffect(() => {
        let finalHeight = (layoutTotalHeight || 0) - (layoutHeaderHeight || 0) - (layoutSubheaderHeight || 0) - (layoutMessageCreatorHeight || 0);
        setStyle({
            height: finalHeight + 'px',
            overflowY: 'auto'
        });

    }, [layoutTotalHeight, layoutMessageCreatorHeight, layoutHeaderHeight, layoutSubheaderHeight]);

    return (
        <AppSectionContainer name={APP_SECTION_CONTENT} id='threadCanvasSection' style={style}
                             className='g-bg-g-grey-100'>
            {subject ?
                <ThreadCanvas/>
                : null}

            {!subject && taskStatus && taskStatus !== 'EXITED'?
                <EmptyCenterCanvas/>
                : null}

            {!subject && (!taskStatus || taskStatus === 'EXITED')?
                <ExitedCenterCanvas/>
                : null}
        </AppSectionContainer>
    )
};

export default ApplicationContent;