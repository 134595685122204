import React from "react";
import {Button} from "@george-labs.com/design-system";
import {Col, Row} from "reactstrap";

export const ActionStripeButton = ({disabled, onClick, variant = Button.VARIANT.PRIMARY, label, title, icon}) => {

    return (
        <Row className="justify-content-center">
            <Col xs={10}>
                <Button title={title} onClick={onClick} disabled={disabled} variant={variant} icon={icon} fullWidth={Button.FULL_WIDTH.ALWAYS} className="mb-3">
                    {label}
                </Button>
            </Col>
        </Row>
    )
};

export default ActionStripeButton;