/**
 * This class wraps all the data loaders needed. If you need to load anything else, simply add it to this component.
 */

import UserRoleEvaluator from "../roles/userRoleEvaluator";
import ThreadPolling from "./threadPolling";
import PhotosFetching from "./photosFetching";
import React from "react";
import { useSelector } from "react-redux";

const DataLoader = () => {
    const cidla = useSelector(state => state.messenger.cidla || "");

    return (
        <>
            <UserRoleEvaluator />
            {cidla ?
                <>
                <ThreadPolling />
                </>
                : null }
            <PhotosFetching />
        </>
    );
}

export default DataLoader;