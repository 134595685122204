import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {useEffect} from "react";
import {getUserPhoto} from "../../actions/userActions";


const PhotosFetching = () => {

    const dispatch = useDispatch();
    const messages = useSelector(state => state?.messenger?.thread?.messages || []);


    useEffect(() => {
        if(messages && messages.length > 0){
            let uniqueUsernames = [];
            messages.forEach(m => {//
                if(m.username && !uniqueUsernames.includes(m.username)){
                    uniqueUsernames.push(m.username)
                }
            });
            uniqueUsernames.map(async username => {
                await dispatch(getUserPhoto(username))
            });
        }
    }, [dispatch, messages]);

    return null;
};

export default PhotosFetching;