import React from "react";
import {ButtonGroup, Button} from "@george-labs.com/design-system";


const ActionsModalFooter = ({primary, secondary, cancel}) => {

    return (
        <ButtonGroup>
            {secondary ?
                <Button variant={Button.VARIANT.SECONDARY} onClick={secondary.onClick}
                        disabled={secondary.disabled}>
                    {secondary.text}
                </Button> : null}

            {cancel ?
                <Button variant={Button.VARIANT.SECONDARY} onClick={cancel.onClick}
                        disabled={cancel.disabled}>
                    {cancel.text}
                </Button> : null}


            {primary ?
                <Button variant={Button.VARIANT.PRIMARY} onClick={primary.onClick}
                        disabled={primary.disabled}>
                    {primary.text}
                </Button> : null}
        </ButtonGroup>
    )
};

export default ActionsModalFooter