import React, {useRef} from "react";
import ReactQuill from "react-quill";
import {MAX_MESSAGE_LENGTH} from "../../../core/const";

/**
 * The setting describing which options are to be displayed to the user.
 */
const richtextToolbar = {
  toolbar: [
    ['bold'],
    [{ 'list': "bullet" }],
    ['link'],
    ['clean']
  ],
};

/**
 * The formats part defines the HTML types that can be present in the output.
 * Example: If I paste a content of a document from Word, all other tags will be striped.
 */
const formats = [
  'bold',
  'list', 'bullet',
  'link'
];

const MobileRichtextInput = ({ onChange, disabled, value }) => {
    console.log("MobileRichtextInput value ", value);

    const reactQuillRef = useRef();

    const handleChange = (value) => {
        const quill = reactQuillRef.current;

        quill.editor.on('text-change', function (delta, old, source) {
            if (quill.editor.getLength() > MAX_MESSAGE_LENGTH) {
                quill.editor.deleteText(MAX_MESSAGE_LENGTH, quill.editor.getLength());
            }}
        );

        onChange(value);
    }

    return (
        <ReactQuill
          id={"bubble-toolbar"}
          theme="bubble"
          ref={reactQuillRef}
          modules={richtextToolbar}
          formats={formats}
          value={value}
          readOnly={disabled ? true : false}
          onChange={(newValue) => { handleChange(newValue) }} />
    );
}

export default MobileRichtextInput;