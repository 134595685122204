import {CANCEL_MODAL_TOGGLE, LAYOUT_UPDATE, MENU_TOGGLED, NOTES_TOGGLED} from "../core/const";

const initialState = {
    header: 0,
    actionStripe: 0,
    menuOpen: false,
    notesOpen: false,
    cancelModalOpen: false
};

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case LAYOUT_UPDATE:
            return {...state, [action.component] : action.height};
        case MENU_TOGGLED:
            return {...state, menuOpen: action.open};
        case NOTES_TOGGLED:
            return {...state, notesOpen: action.open};
        case CANCEL_MODAL_TOGGLE:
            return {...state, cancelModalOpen: action.open};
        default:
            return state;
    }
};

export default layoutReducer;
