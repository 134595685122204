import fetchIntercept from "fetch-intercept";
import dayjs from "dayjs";


export function extractParamFromURL(param) {
    let url = new URL(window.location.href);
    return url.searchParams.get(param);
}

export function removeParamsFromUrl(params){
    let url = new URL(window.location.href);
    params.forEach(p => url.searchParams.delete(p));
    return url.href;
}

export function registerInterceptor(accessToken) {
    fetchIntercept.clear();
    fetchIntercept.register({
        request: (url, config) => {
            if (!config) {
                config = {};
            }
            if (!config.headers) {
                config.headers = {};
            }
            config.headers['Authorization'] = 'Bearer ' + accessToken;
            config.headers["web-api-key"] = process.env.REACT_APP_WEBAPI_KEY;
            return [url, config];
        }
    });
}

export const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
    );
};

export const generateFormatDate = (value, dateFormat) => {
    return dayjs(new Date(value)).format(dateFormat); //možná bude dateformat asi všude stejný
};

//Translate english day in the week shortcut to czech language shortcut
export const translateDay = (value) => {
    if (value.includes("Mo")) {
        value = value.replace("Mo", "po");
    } else if (value.includes("Tu")) {
        value = value.replace("Tu", "út");
    } else if (value.includes("We")) {
        value = value.replace("We", "st");
    } else if (value.includes("Th")) {
        value = value.replace("Th", "čt");
    } else if (value.includes("Fr")) {
        value = value.replace("Fr", "pá");
    } else if (value.includes("Sa")) {
        value = value.replace("Sa", "so");
    } else if (value.includes("Su")) {
        value = value.replace("Su", "ne");
    }

    return value;
};

export const sortBy = (item, attr) => {
    return item.sort((a, b) => {
        if (a[attr] === b[attr]){
            return 0;
        } else if (a[attr] > b[attr]){
            return 1;
        } else {
            return -1;
        }
    });
};

export const getReadableFileSizeString = (size) => {
    if(size) {
        let fileSizeInBytes = size;
        var i = -1;
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        let result = Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
        return "(" + result + ")";
    }
};