import {USER_LOADING, USER_ROLES_EVALUATED, USER_LOADED, USER_LOADING_FAILED, SET_USERS_PHOTOS} from "../core/const";

const initialState = { identity: null, loading: false, error: false, session: null, roles: [], photos: {}};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOADING:
            return {...state, loading: true};
        case USER_LOADED:
            return {...state, identity: action.identity, loading: false, error: false, session: action.session};
        case USER_LOADING_FAILED:
            return {...state, loading: false, error: true};
        case USER_ROLES_EVALUATED:
            return {...state, roles: action.userRoles};
        case SET_USERS_PHOTOS:{
            const newPhoto = {};
            newPhoto[action.username] = action.photo;
            const obj = Object.assign(state.photos, newPhoto);
            return {...state, photos: {...obj}};
        }
        default:
            return state;
    }
};

export default userReducer;
