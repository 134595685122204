import React from "react";
import {Modal, ModalBody, Button, Icon, Paragraph} from "@george-labs.com/design-system";
import ClientDetail from "../clientDetail";
import {useDispatch, useSelector} from "react-redux";
import {setClientDetailOpen} from "../../actions/messengerActions";
import {CB_ServicingCategoryManual} from "../../core/enums";

const ClientModal = () => {
    const clientDetailOpen = useSelector(state => state?.messenger?.clientDetailOpen || false);
    const clientData = useSelector(state => state?.messenger?.client || {});
    const dispatch = useDispatch();

    const getFooter = () => {
        return (
            <Button onClick={() => dispatch(setClientDetailOpen(false))}>
                Zpět
            </Button>
        )
    };

    const getColor = () => {
        const servicingCategoryManualInt = parseInt(clientData?.servicingCategoryManual);
        if (servicingCategoryManualInt === 7) {
            return Icon.COLOR.BLUE_300;
        } else if ((servicingCategoryManualInt === 9) || (servicingCategoryManualInt === 5)) {
            return Icon.COLOR.BLACK;
        } else if (servicingCategoryManualInt === 6) {
            return Icon.COLOR.YELLOW_900;
        }
        return Icon.COLOR.GREY_500;
    };

    const getTitle = () => {
        return (
            <div>
                <span>
                    {clientData?.fullName}
                </span>
                <span className="d-block text-left small">
                    <Paragraph>
                        <Icon icon={Icon.ICON.DOT_LARGE} color={getColor()} size={Icon.SIZE.SIZE_16}/>
                        <span className="ml-1">
                            {CB_ServicingCategoryManual[clientData?.servicingCategoryManual]}
                        </span>
                    </Paragraph>
                </span>
            </div>
        )
    };

    const handleOnClose = () => {
        dispatch(setClientDetailOpen(false));
    };

    return (
        <Modal isOpen={clientDetailOpen} footer={getFooter()} title={getTitle()} onClose={handleOnClose}>
            <ModalBody>
                <ClientDetail clientData={clientData}/>
            </ModalBody>
        </Modal>
    )
};

export default ClientModal