import Row from "reactstrap/es/Row";
import {
    Button,
    ButtonGroup,
    Col,
    HorizontalFunctionCard,
    HorizontalFunctionCardGroup,
    Icon, Text
} from "@george-labs.com/design-system";
import React from "react";
import {useSelector} from "react-redux";

const ExitedCenterCanvas = () => {
    const properties = useSelector(state => state?.environment?.properties);

    return (
        <>
            <Row className="mt-4">
                <Col>
                    <HorizontalFunctionCardGroup>
                        <HorizontalFunctionCard>
                            <Row>
                                <Col className='text-center mb-4'>
                                    <Icon icon={Icon.ICON.COMMENTS} size={Icon.SIZE.SIZE_128}
                                          color={Icon.COLOR.GREY_500}/>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col className='text-center'>
                                    <Text>Pro zahájení nové konverzace s klientem založte nový požadavek přes Service
                                        Starter.</Text>
                                </Col>
                            </Row>
                        </HorizontalFunctionCard>
                        <HorizontalFunctionCard>
                            <ButtonGroup className="text-center">
                                <Button variant={Button.VARIANT.PRIMARY} href={properties["starter.app.url"]}>Service starter</Button>
                            </ButtonGroup>
                        </HorizontalFunctionCard>
                    </HorizontalFunctionCardGroup>
                </Col>
            </Row>
        </>
    )
};

export default ExitedCenterCanvas;