import useInterval from '../../hooks/useInterval';
import { useSelector } from 'react-redux';
import { doMessagePolling } from '../../actions/messengerActions';
import { useDispatch } from 'react-redux';

const THREAD_POLLING_PERIOD = 15000;

const ThreadPolling = () => {

    const dispatch = useDispatch();
    const cidla = useSelector(state => state.messenger.cidla || "");

        useInterval(
            () => (dispatch(doMessagePolling(cidla))),
            THREAD_POLLING_PERIOD
    );
    

    return null; // Do not reveal yourself, stay in the shadows.
}

export default ThreadPolling;