import {ACTIVE_STATES, COL_MIT, FHC_MIT, KYC_MIT, SCMIT} from "../../../core/const";
import {
    TASK_COMPLETE,
    TASK_HAND_OVER_AJ,
    TASK_HAND_OVER_HYPO,
    TASK_HAND_OVER_KCP,
    TASK_RELATIONS,
    TASK_RESTORE,
    TASK_SOLVER_ALL_ROLES,
    TASK_SOLVER_FO_ROLES,
    TASK_SOLVER_KC_CZ_ROLES,
    TASK_SOLVER_KC_EN_ROLES,
    TASK_SOLVER_KC_HYPO_ROLES,
    TASK_SOLVER_KC_ROLES,
    TASK_SOLVER_ENABLE_TO_EN_DELEGATION_ROLES_FO,
    TASK_SOLVER_ENABLE_TO_EN_DELEGATION_ROLES_BO,
    TASK_TAKE,
    TASK_TEXT_CLIENT_SLA,
    TASK_TEXT_TASK_ACTIVE,
    TASK_TEXT_TASK_BANKER_INITIALIZED,
    TASK_TEXT_TASK_DELETED_BY_CLIENT,
    TASK_TEXT_TASK_DONE,
    TASK_TEXT_TASK_SUBSTITUTE_EN,
    TASK_TEXT_TASK_SUBSTITUTE_HYPO,
    TASK_TEXT_TASK_SUBSTITUTE_KC,
    CASE_DELEGATE, CASE_CANCEL, HT_MIT_BO
} from "./actionStripeConst";
import {Badge} from "@george-labs.com/design-system";

const DELEGATE_HYPO_VERSION_PARAM = process.env.REACT_APP_DELEGATE_HYPO_REVISION;
const DELEGATE_EN_VERSION_PARAM_SCMIT = process.env.REACT_APP_DELEGATE_EN_REVISION_SCMIT;
const DELEGATE_EN_VERSION_PARAM_KYC_MIT = process.env.REACT_APP_DELEGATE_EN_REVISION_KYC_MIT;
const DELEGATE_EN_VERSION_PARAM_COL_MIT = process.env.REACT_APP_DELEGATE_EN_REVISION_COL_MIT;
const DELEGATE_EN_VERSION_PARAM_FHC_MIT = process.env.REACT_APP_DELEGATE_EN_REVISION_FHC_MIT;

export const findConfiguration = (humanTask, thread, client, user) => {
    if(!humanTask || !user){
        return;
    }
    //1] did banker respond
    let bankerResponded = didBankerRespond(thread);
    //1] did client respond
    let clientResponded = didClientRespond(thread);
    //2] was conversation initialized by client
    let clientInitialized = isConversationClientInitialized(thread);
    //3] was conversation initialized by banker
    let bankerInitialized = isConversationBankerInitialized(thread);
    //4] return config
    let buttons = getButtonConfig(humanTask, client, user, thread);
    let sendDisabled = getSendDisabled(humanTask, user, thread);
    let stateVariant = getStateVariant(humanTask, user);
    let stateText = getStateText(humanTask, thread);
    let stateIcon = getStateIcon(humanTask);
    let text = getStripeText(humanTask, user, bankerResponded, clientInitialized, bankerInitialized, clientResponded, thread);

    return {buttons, sendDisabled, stateVariant, text, stateText, stateIcon}
};

const SYSTEM_USERNAME = "system";

function didBankerRespond (thread) {
    return thread?.messages && thread.messages.some(m => m.username && m.username !== SYSTEM_USERNAME);
}

function didClientRespond (thread) {
    return thread?.messages && thread.messages.some(m => !m.username);
}

function isConversationClientInitialized(thread) {
    if(!thread?.messages || thread?.messages.length === 0){
        return false;
    }

    let messages = thread?.messages?.sort((m1, m2) => m1.createdAt - m2.createdAt);
    return !messages?.[0]?.username;
}

function isConversationBankerInitialized(thread) {
    if(!thread?.messages || thread?.messages.length === 0){
        return true;
    }

    let messages = thread?.messages?.sort((m1, m2) => m1.createdAt - m2.createdAt);
    return messages?.[0].username;
}

export function isTaskInStatus (humanTask, statusArr) {
    return statusArr?.some(s => s.key === humanTask?.taskStatus)
}

export function isTaskActive (humanTask) {
    return isTaskInStatus(humanTask, ACTIVE_STATES);
}

function isClientInServicing(client, servicingIdsArr){
    if(!servicingIdsArr || servicingIdsArr.length === 0){
        return true;
    }
    return servicingIdsArr?.some(s => s === client.servicingCategoryManual)
}

function isInSameSegment(client, user){
    return TASK_RELATIONS.some(tr => tr.servicingCategories.some(sc => sc === client.servicingCategoryManual) && user.roles.some(r => r === tr.role));
}

export function isUserInRoles(user, roleArr){
    return user?.roles.some(r => roleArr.some(ra => r === ra));
}

function isUserInSolvingRole(user) {
    return TASK_SOLVER_ALL_ROLES.some(allR => user?.roles.some(r => r === allR));
}

export function isUserInKCRole(user){
    return TASK_SOLVER_KC_ROLES.some(allR => user?.roles.some(r => r === allR));
}

export function isUserInFORole(user){
    return TASK_SOLVER_FO_ROLES.some(allR => user?.roles.some(r => r === allR));
}

export function isActualOwner(ht, user){
    return ht.actualOwner?.toLowerCase() === user?.username?.toLowerCase()
}

export function canSolve(ht, user){
    return ht.potentialOwners.some(po => user?.roles?.some(r => po === r));
}


function isHTWithPotentialRoles(ht, rolesArr){
    return ht?.potentialOwners.some(po => rolesArr.some(ra => po === ra));
}

function isCancelButton(thread){
    return !thread?.messages || thread?.messages.length === 0;
}

function isCompleteBtn(ht, user){
    return isTaskActive(ht) && isActualOwner(ht, user);
}

function isDelegateBtn(ht, user){
    return isTaskActive(ht) && isActualOwner(ht, user) && isUserInRoles(user, ["SMART_MIT_solver_backOffice_HYPO"])
}

function isTakeBtn(ht, user, client){
    return isTaskActive(ht) && !isActualOwner(ht, user) && isInSameSegment(client, user) && canSolve(ht, user);
}

function isRestoreBtn(ht, user, thread){
    return !isTaskActive(ht) && canSolve(ht, user) && !isThreadDeleted(thread) && !isThreadClosed(thread);
}

function isValidRevisionForCaseType(ht) {
    switch (ht.caseType) {
        case SCMIT:
            return parseInt(ht.version, 10) >= DELEGATE_EN_VERSION_PARAM_SCMIT
        case KYC_MIT:
            return parseInt(ht.version, 10) >= DELEGATE_EN_VERSION_PARAM_KYC_MIT
        case COL_MIT:
            return parseInt(ht.version, 10) >= DELEGATE_EN_VERSION_PARAM_COL_MIT
        case FHC_MIT:
            return parseInt(ht.version, 10) >= DELEGATE_EN_VERSION_PARAM_FHC_MIT
    }
    return false;
}

function isDelegateAjBtn(ht, user){
    return isTaskActive(ht) && isActualOwner(ht, user) && (isUserInRoles(user, TASK_SOLVER_ENABLE_TO_EN_DELEGATION_ROLES_BO) || (isUserInRoles(user, TASK_SOLVER_ENABLE_TO_EN_DELEGATION_ROLES_FO) && isValidRevisionForCaseType(ht) && ht.name !== HT_MIT_BO));
}

function isDelegateHypoBtn(ht, user){
    //DELEGATE_HYPO_VERSION_PARAM was deleted from env properties, button should not be visible - DF-62104
    return isTaskActive(ht) && isActualOwner(ht, user) && isUserInRoles(user, TASK_SOLVER_KC_ROLES) && parseInt(ht.version, 10) >= DELEGATE_HYPO_VERSION_PARAM;
}

function isCaseDelegateBtn(ht, user){
    return isTaskActive(ht) && isActualOwner(ht, user) && isUserInRoles(user, ["SMART_MIT_solver_collections"])
}

function getButtonConfig(ht, client, user, thread){
    let buttons = [];

    // When Thread is deleted or closed, no buttons are available for user
    if (isThreadDeleted(thread) || isThreadClosed(thread)) {
        return buttons;
    }

    //0] Cancel button - THIS IS EXCEPTIONAL! WE DO NOT WANT ANY OTHER BUTTON THAN CANCEL IN CASE CONVERSATION IS NOT EVEN STARTED
    if(isCancelButton(thread)){
        pushBtn(buttons, CASE_CANCEL, false, 0, false);
        return buttons;
    }
    //1] Delegate - 3
    if(isDelegateBtn(ht, user)){
        pushBtn(buttons, TASK_HAND_OVER_KCP, false, 6, true);
    }
    //2] Take - 10
    if(isTakeBtn(ht, user, client)){
        pushBtn(buttons, TASK_TAKE, true, 2, false);
    }
    //3] Restore - 16
    if(isRestoreBtn(ht, user, thread)){
        pushBtn(buttons, TASK_RESTORE, true, 5, false);
    }
    //4] Delegate Aj - 19
    if(isDelegateAjBtn(ht, user)){
        pushBtn(buttons, TASK_HAND_OVER_AJ, false, 7, true);
    }
    //5] Complete btn - 20
    if(isCompleteBtn(ht, user)){
        pushBtn(buttons, TASK_COMPLETE, true, 1, false);
    }
    //6] Wake up btn - 21 - deleted
    //7] Suspend btn - 22 - deleted
    //8] Delegate Hypo - 23
    if(isDelegateHypoBtn(ht, user)){
        pushBtn(buttons, TASK_HAND_OVER_HYPO, true, 8, true);
    }
    //9] Case Delegate
    if(isCaseDelegateBtn(ht,user)){
        pushBtn(buttons, CASE_DELEGATE, false, 9, true);
    }
    return buttons;
}

function pushBtn(arr, btn, primary, importance, handOver){
    arr.push({id: btn, primary: primary, importance, handOver})
}

function getSendDisabled(ht, user, thread){
    return !isTaskActive(ht) || !canSolve(ht, user) || !isActualOwner(ht, user) || isThreadDeleted(thread) || isThreadClosed(thread)
}

export function isThreadDeleted(thread) {
    return thread?.deletedAt
}

export function isThreadClosed(thread) {
    return thread?.closedAt
}

export function isHumanTaskExpired(humanTask) {
    return humanTask?.expirationDate
}

function isInfo(ht, user){
    return isTaskActive(ht) && !isActualOwner(ht, user)
}

function isPrimary(ht, user){
    return isTaskActive(ht) && isActualOwner(ht, user)
}

function isSecondary(ht){
    return !isTaskActive(ht)
}

function getStateText(ht, thread){
    if(isTaskActive(ht)){
        return 'Aktivní';
    } else if(isThreadDeleted(thread)){
        return 'Smazaná'
    } else if(isThreadClosed(thread)){
        return 'Uzavřená';
    } else if(isHumanTaskExpired(ht)){
        return 'Nevyřešená';
    } else{
        return 'Ukončená';
    }
}

function getStateIcon(ht){
    return isTaskActive(ht) ? Badge.ICON.COMMENTS : Badge.ICON.CLOSED;
}

function getStateVariant(ht, user){

    if(isPrimary(ht, user)){
        return Badge.VARIANT.SUCCESS;
    }

    if(isSecondary(ht)){
        return Badge.VARIANT.STATE;
    }

    if(isInfo(ht, user)){
        return Badge.VARIANT.TODO;
    }
}

function getStripeText(ht, user, bankerRespond, clientInitialized, bankerInitialized, clientRespond, thread){
    if(isThreadDeleted(thread)){
        return TASK_TEXT_TASK_DELETED_BY_CLIENT;
    }

    if(!isTaskActive(ht)){
        return TASK_TEXT_TASK_DONE
    }

    if(isTaskActive(ht) && isActualOwner(ht, user) && bankerRespond){
        return TASK_TEXT_TASK_ACTIVE
    }

    if(isTaskInStatus(ht, ACTIVE_STATES) && !canSolve(ht, user) && isHTWithPotentialRoles(ht, TASK_SOLVER_KC_CZ_ROLES)
        && !isUserInKCRole(user)){
        return TASK_TEXT_TASK_SUBSTITUTE_KC;
    }

    if(isTaskInStatus(ht, ACTIVE_STATES) && !canSolve(ht, user) && isHTWithPotentialRoles(ht, TASK_SOLVER_KC_EN_ROLES)
        && !isUserInKCRole(user)){
        return TASK_TEXT_TASK_SUBSTITUTE_EN;
    }

    if(isTaskInStatus(ht, ACTIVE_STATES) && !canSolve(ht, user) && isHTWithPotentialRoles(ht, TASK_SOLVER_KC_HYPO_ROLES)
        && !isUserInKCRole(user)){
        return TASK_TEXT_TASK_SUBSTITUTE_HYPO;
    }

    if(isTaskActive(ht) && isActualOwner(ht, user) && !bankerRespond && clientInitialized){
        return TASK_TEXT_CLIENT_SLA;
    }

    if(isTaskActive(ht) && isActualOwner(ht, user) && bankerInitialized && !clientRespond){
        return TASK_TEXT_TASK_BANKER_INITIALIZED;
    }

    if(isTaskActive(ht) && !isActualOwner(ht, user)){
        return TASK_TEXT_TASK_ACTIVE;
    }
}