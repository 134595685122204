import React from 'react';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {Button, Icon, Tooltip} from "@george-labs.com/design-system";
import GeorgeSpinner from "../../loader/georgeSpinner";
import {getReadableFileSizeString} from "../../../core/utils";
import {DOCUMENT_CHECK_STATE_CZ} from "../../../core/enums";

const AttachmentItem = (props) => {

    const {attachment, handleDownload, isOutgoing} = props;

    const handleDownloadLocal = () => {
        handleDownload(attachment);
    };

    const generateFileDescription = () => {
        return (attachment.filename ? attachment.filename : attachment.duid);
    };

    const getButton = () => {
        if (!isAntivirusOk(attachment)){
            return (
                <Tooltip content={getAntivirusMessage(attachment)} className="d-inline-block ml-1">
                    <Icon icon={Icon.ICON.ERROR} className="text-danger"/>
                </Tooltip>
            )
        }

        if (attachment?.flag?.sending) {
            return (
                <Tooltip content={"Odesílám dokument"} className="d-inline-block ml-1">
                    <GeorgeSpinner size="xs"/>
                </Tooltip>
            )
        }else if(attachment?.flag?.error){
            return (
                <Tooltip content={"Dokument se nepodařilo odeslat, kliknutím na ikonku v pravém spodním rohu to ale můžete zkusit znovu"} className="d-inline-block ml-1">
                    <Icon icon={Icon.ICON.WARNING} className="text-warning"/>
                </Tooltip>
            )
        }else{
            return (
                <Tooltip content={"Stáhnout dokument"} className="d-inline-block ml-1">
                    <Button onClick={handleDownloadLocal} className={!isOutgoing ? "downloadButtonIn" : ""} size={"small"}>
                        <Icon icon={Icon.ICON.FILE_DOWNLOAD} />
                    </Button>
                </Tooltip>
            )
        }
    };

    const isAntivirusOk = (attachment) => {
         if(attachment.state && attachment.state !== 'CHECKED'){
            return false;
         }

         return true;
    };

    const getAntivirusMessage = (attachment) => {
         if(attachment.state){
             return <>
                 <span className='mr-2'>
                     Dokument od klienta neprošel antivirovou kontrolou z důvodu:
                 </span>
                 <span className={"font-weight-bold"}>
                     {DOCUMENT_CHECK_STATE_CZ?.[attachment.state]}
                 </span>
                 </>
         }

         return 'Dokument od klienta neprošel antivirovou kontrolou'
    };

    return (
        <Row>
            <Col md={10}>
                <span onClick={isAntivirusOk(attachment) ? handleDownloadLocal : null} className="pointer">
                    <span className="font-weight-bold font-italic">{generateFileDescription()}</span>
                    <small className={"ml-2 " + (isOutgoing ? 'g-fg-g-grey-500' : 'g-fg-g-grey-800')}>{getReadableFileSizeString(attachment.size)}</small>
                </span>
            </Col>
            <Col className="col-md-auto text-right pb-0">
                {getButton()}
            </Col>
        </Row>
    )
};

export default AttachmentItem;