import {Button, Icon, Tooltip} from "@george-labs.com/design-system";
import GeorgeSpinner from "../../loader/georgeSpinner";
import {generateFormatDate} from "../../../core/utils";
import React from "react";

const MessageTooltip = ({message, isOutgoing, handleResendMessage, text}) => {

    const hasErrors = () => {
        return message?.attachments?.some(a => a?.flag?.error) || message?.flag?.error
    };

    const getTooltip = () => {
        if (message?.flag?.sending) {
            return (
                <Tooltip content={"Odesílám zprávu"} className="d-inline-block ml-1">
                    <GeorgeSpinner size="xs"/>
                </Tooltip>
            )
        } else if (hasErrors()) {
            return (
                <Tooltip content={"Zprávu není možné odeslat. Je možné, že došlo k výpadku systému nebo vkládáte nepovolený formát."}
                         className="d-inline-block ml-1">
                    <Button onClick={handleResendMessage} className="bg-danger" size={"small"}>
                        <Icon icon={Icon.ICON.COMMENT_IMPORTANT}/>
                    </Button>
                </Tooltip>
            )
        } else if (message?.flag?.unsent) {
            return (
                <Tooltip content={"Zpráva ještě nebyla odeslána"} className="d-inline-block ml-1">
                    <Icon icon={Icon.ICON.MEDIA_STOP}/>
                </Tooltip>
            )
        } else if (message.readAt) {
            return (
                <Tooltip content={"Zobrazeno " + generateFormatDate(message.readAt, "HH:mm")}
                         className="d-inline-block ml-1">
                    <Icon icon={Icon.ICON.CHECKMARK}/>
                </Tooltip>
            )
        } else {
            return (
                <Tooltip content={"Odesláno " + generateFormatDate(message.createdAt, "HH:mm")}
                         className="d-inline-block ml-1">
                    <Icon icon={Icon.ICON.UNREAD}/>
                </Tooltip>
            )
        }
    };

    return (
        <div className={"pt-0 " + (isOutgoing ? 'g-fg-white' : 'g-fg-g-grey-800')}>
            <span className={(isOutgoing ? 'g-fg-white' : 'g-fg-blue-300') + " small font-weight-bold mr-1"}>{text}</span>
            <span className="small pt-0">{generateFormatDate(message.createdAt, "HH:mm")}</span>
            <span className={"pt-0 " + (isOutgoing ? 'g-fg-white' : 'g-fg-g-grey-800')}>
                 {getTooltip()}
            </span>
        </div>
    )


};

export default MessageTooltip