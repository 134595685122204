import { Row, Col } from "reactstrap";
import {Icon, Paragraph} from "@george-labs.com/design-system";
import CopyIcon from "./feedback/copyIcon";

const SectionRow = ({icon, text, emptyText = 'Není k dispozici', active, copy = false}) => {

    const getTextClassName = () => {
        let classes = [];
        if(!text){
            classes.push('text-muted');
        }else if(active){
            classes.push('text-primary');
            classes.push('font-weight-bold');
            classes.push('text-underline');
        }
        return classes.join(' ');
    };

    const getIconClassName = () => {
        let classes = [];
        classes.push("text-muted");
        classes.push("mr-3");
        return classes.join(' ');
    };

    const getText = () => {
        if(!text){
            return emptyText;
        }
        return text;
    };

    return (
        <Row className="mb-1">
            <Col>
                <Paragraph>
                    <Icon icon={icon} size={Icon.SIZE.SIZE_24} className={getIconClassName()}/>
                    <span className={getTextClassName()}>{getText()}</span>
                    {copy ?
                        <CopyIcon text={getText()}/>
                    : null}
                </Paragraph>
            </Col>
        </Row>
    );
};

export default SectionRow;