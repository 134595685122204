import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {resendMessage, setClientDetailOpen} from "../../../actions/messengerActions";
import {Avatar, Card, Icon, Text} from "@george-labs.com/design-system";
import parse from "html-react-parser";
import AttachmentItem from "./attachmentItem";
import MessageTooltip from "./messageTooltip";
import React from "react";
import {useDispatch} from "react-redux";

const ClientMessage = ({message, client, handleDownloadDocument}) => {
    const dispatch = useDispatch();

    const handleResendMessage = () => {
        dispatch(resendMessage(message));
    };

    const handleDownload = (doc) => {
        handleDownloadDocument(doc)
    };

    return (
        <>
            <Row key={message.id} className='mb-2'>
                <Col className="col-auto align-items-end d-flex pr-2">
                    <Avatar className="selectable" icon={Avatar.ICON.USER} color={Avatar.COLOR.BLUE_300} onClick={() => dispatch(setClientDetailOpen(true))}/>
                </Col>
                <Col className="col-auto align-items-end d-flex px-0 my-3">
                    <div className='arrow-left'/>
                </Col>
                <Col xs={9} sm={10} md={8} className="pl-0">
                    <Card className="incoming-message py-0 my-0" color={Card.COLOR.WHITE}>
                        <Row>
                            <Col>
                                <span className="messageHref">
                                    <Text>{message.body ? parse(message.body) : ""}</Text>
                                </span>
                            </Col>
                        </Row>
                        {message.attachments?.map(a => {
                            return <AttachmentItem key={a.uuid}
                                                   attachment={a}
                                                   handleDownload={handleDownload}
                                                   isOutgoing={false}/>
                        })}
                        <Row className="p-0">
                            <Col className="text-right">
                                <MessageTooltip message={message}
                                                text={client?.fullName}
                                                isOutgoing={false}
                                                handleResendMessage={handleResendMessage}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )
};

export default ClientMessage