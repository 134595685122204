import MessengerModal from "./messengerModal";
import React from "react";
import ActionsModalFooter from "./actionsModalFooter";

export const ActionsBasicModal = ({isOpen, onToggle, config}) => {

    const getFooter = () => {
        let primary = {
            onClick: config.primary.onClick,
            text: config.primary.label
        };

        let secondary = {
            onClick: config.secondary?.onClick,
            text: config.secondary?.label
        };

        let cancel = {
            onClick: config.back.onClick,
            text: config.back.label
        };

        return (
            <ActionsModalFooter
                primary={primary}
                secondary={config.secondary? secondary : null}
                cancel={cancel}
            />
        )
    };

    return (
        <MessengerModal
            size="xs"
            isOpen={isOpen}
            onToggle={onToggle}
            header={config.header}
            body={config.body}
            footer={getFooter()}/>
    )
};

export default ActionsBasicModal