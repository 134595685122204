import React, { useState } from "react";
import {
    Button,
    ButtonGroup,
    HorizontalFunctionCardGroup,
    HorizontalFunctionCard,
    Heading,
    TextInput,
    FormField
} from "@george-labs.com/design-system";
import { Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { toggleCancelModal } from "../../actions/layoutAction";
import { setFutureThreadSubject } from "../../actions/messengerActions";

const EmptyCenterCanvas = () => {
    const dispatch = useDispatch();

    const [subject, setSubject] = useState("");

    const threadSubjectChanged = (e) => {
        setSubject(e.target.value);
    }

    const isButtonDisabled = () => {
        return !subject;
    }

    const saveSubject = () => {
        dispatch(setFutureThreadSubject(subject));
    }

    return (
        <Row className="mt-4">
            <Col>
                <HorizontalFunctionCardGroup>
                    <HorizontalFunctionCard contentClassName="text-center" heading={
                        <Heading level={2}>Nová konverzace</Heading>}
                    />
                    <HorizontalFunctionCard>
                        <Row className="justify-content-center">
                            <Col lg={8} md={10}>
                                <FormField
                                    required
                                    name="textWithButtonAddon"
                                    label="Téma konverzace s klientem"
                                    input={TextInput}
                                    labelPosition={FormField.LABEL_POSITION.ASIDE}
                                    placeholder="Nová kreditní karta"
                                    onChange={threadSubjectChanged}
                                />
                            </Col>
                        </Row>
                    </HorizontalFunctionCard>
                    <HorizontalFunctionCard>
                        <ButtonGroup className="text-center">
                            <Button variant={Button.VARIANT.SECONDARY} onClick={() => dispatch(toggleCancelModal(true))}>Storno</Button>
                            <Button variant={Button.VARIANT.PRIMARY} onClick={saveSubject} disabled={isButtonDisabled()}>Začít</Button>
                        </ButtonGroup>
                    </HorizontalFunctionCard>
                </HorizontalFunctionCardGroup>
            </Col>
        </Row>
    )
};

export default EmptyCenterCanvas