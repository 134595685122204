import {Badge, Icon} from "@george-labs.com/design-system";
import React from "react";
import {Col, Row} from "reactstrap";

const ActionStripeState = ({config, inMenuSection}) => {

    return (
        <Row>
            {inMenuSection ?
                <Col className='d-block d-sm-none'>
                    <Badge variant={config?.stateVariant} icon={config?.stateIcon} text={config?.stateText}/>
                </Col> : null}

            {!inMenuSection ?
                <Col className='col-auto'>
                    <span className='d-block d-sm-none'>
                        <Badge variant={config?.stateVariant} icon={config?.stateIcon} text={' '}/>
                    </span>
                    <span className='d-none d-sm-block'>
                        <Badge variant={config?.stateVariant} icon={config?.stateIcon} text={config?.stateText}/>
                    </span>
                </Col> : null}
        </Row>
    )
};

export default ActionStripeState