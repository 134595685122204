import { useRef, useEffect } from "react";

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // updating callback, whenever it changes
    useEffect (() => {
        savedCallback.current = callback;
    }, [callback]);

    // setting up the interval
    useEffect(() => {
        
        const act = () => {
            savedCallback.current();
        }

        if (delay){
            const id = setInterval(act, delay);
            // stop calling the function, when component unmounts.
            return () => {
                clearInterval(id);
            };
        }
    })
} 


export default useInterval;