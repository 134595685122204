import React from "react";
import {Snackbar, SnackbarButton, SnackbarItem, Button} from "@george-labs.com/design-system";
import {useSelector} from "react-redux";
import CountdownInfo from "../feedback/countdownInfo";

const ApplicationSnackbar = () => {
    const alerts = useSelector(state => state?.alerts.alerts || []);

    const getVariant = (a) => {
        if (a.color === "success") {
            return SnackbarItem.VARIANT.SUCCESS;
        }

        if (a.color === "danger") {
            return SnackbarItem.VARIANT.ERROR;
        }

        if (a.timeout) {
            return SnackbarItem.VARIANT.BUSY;
        }

        return SnackbarItem.VARIANT.DEFAULT;
    };

    const getButton = (a) => {
        if (a.onClick && a.actionLabel) {
            return (
                <SnackbarButton onClick={a.onClick} variant={Button.VARIANT.PRIMARY}>
                    {a.actionLabel}
                </SnackbarButton>
            )
        }
    };

    const getExpireTime = (timeout) => {
        let futureMillis = new Date().getTime() + timeout;
        return new Date(futureMillis);
    };

    const getCountDown = (a) => {
        if(a.timeout && a.onClick){
            return (
                <CountdownInfo onExpire={a.onClick} expireTime={getExpireTime(a.timeout)}/>
            )
        }
    };

    return (
        <>
            <Snackbar>
                {alerts.map(a => {
                    return (
                        <SnackbarItem
                            key={a.id}
                            button={getButton(a)}
                            variant={getVariant(a)}
                            autoCloseable={SnackbarItem.AUTO_CLOSEABLE.DISABLED}>
                            {a.label}
                            {getCountDown(a)}
                        </SnackbarItem>
                    )
                })}
            </Snackbar>
        </>
    )
};

export default ApplicationSnackbar