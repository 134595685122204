import Col from "reactstrap/es/Col";
import { Badge } from "@george-labs.com/design-system";
import React from "react";

const BadgeComp = (props) => {

    const { label } = props;

    return <Col className="text-center mb-1">
            <Badge variant={Badge.VARIANT.CATEGORY} text={label}/>
        </Col>
}

export default BadgeComp;