import MessengerModal from "./messengerModal";
import React from "react";
import {Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import ActionsModalFooter from "./actionsModalFooter";
import {useDispatch, useSelector} from "react-redux";
import {fireComplain, fireRestore} from "../../actions/humanTaskActions";

export const ActionsRestoreModal = ({isOpen, onToggle}) => {

    const complaining = useSelector(state => state?.humanTask?.complaining);
    const cidla = useSelector(state => state?.messenger?.cidla);
    const properties = useSelector(state => state?.environment?.properties);

    const dispatch = useDispatch();

    const handlePrimary = () => {
        dispatch(fireComplain(cidla)).then(() => onToggle())
    };

    const getBody = () => {
        return (
            <>
                <Row>
                    <Col>
                        Chystáte se obnovit ukončenou konverzaci. Opravdu chcete s klientem pokračovat v této
                        konverzaci? Pro založení nové konverzace na jiné téma zvolte možnost Založit novou konverzaci.
                    </Col>
                </Row>
            </>
        )
    };

    const doRestore = () => {
        dispatch(fireRestore());
    };

    const getFooter = () => {
        let primary = {
            onClick: handlePrimary,
            text: 'Obnovit tuto konverzaci'
        };

        let secondary = {
            onClick: () => doRestore(),
            text: 'Založit novou konverzaci'
        };

        let cancel = {
            text: 'Zpět na přehled',
            onClick: onToggle
        };

        return (
            <ActionsModalFooter primary={primary} cancel={cancel} secondary={secondary}/>
        )
    };

    return (
        <MessengerModal
            size="xs"
            error={complaining.error}
            processing={complaining.progress}
            processingText="Obnovuji konverzaci"
            isOpen={isOpen}
            onToggle={onToggle}
            header="Obnovit konverzaci"
            body={getBody()}
            footer={getFooter()}
        />
    )
};

export default ActionsRestoreModal